import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { NetPayImage } from 'utils/svgs';
import GlobalHeader from '../GlobalHeader';
import NetPayTable from './Table';
import styles from './index.module.scss';

type NetPayProps = {
	selectedPayrollId: string;
	headerData: any;
};

const NetPayComponent = (props: NetPayProps) => {
	const { selectedPayrollId, headerData } = props;
	const navigate = useNavigate();
	return (
		<Row>
			<Col span={14}>
				<div className={styles['work-hours__table']}>
					<GlobalHeader headerData={headerData} />
					<NetPayTable selectedPayrollId={selectedPayrollId} />
				</div>
			</Col>
			<Col span={1}></Col>
			<Col span={9}>
				<div>
					<NetPayImage />
				</div>
			</Col>
		</Row>
	);
};

export default NetPayComponent;
