import { Button, Col, Modal, Row, Tabs } from 'antd';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastText } from 'utils/utils';
import {
	LoadingOutlined,
	HistoryOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { employeeApi } from 'Api/employee';
import { salaryApi } from 'Api/salaryDetails';
// import EmploymentDetails from './EmployentDetails';
// import TaxationDetails from './TaxationDetails';
// import SalaryDetails from './SalaryDetails';
// import PaymentInfo from './PayementInfo';
// import Documents from './Documents';
import { PermissionContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import ModalWrapper from 'components/Global/ModalWrapper';
import { CloseSvg, DeductionsIconSvg, EarningsIconSvg } from 'utils/svgs';
import styles from './index.module.scss';
import EmployeeDetails from './EmployeeDetails';
import './index.scss';
import TaxationDetails from './TaxationDetaills';
import SalaryDetails from 'components/AddEmployee/SalaryDetails';
import Documents from 'components/AddEmployee/Documents';
import PaymentInfoDetails from './PaymentInfo/Table';
import PaymentDetails from './PaymentInfo';

type Props = {
	onCancel: () => void;
	isViewOnlyEmployeeDetails: boolean;
	fetchEmployees: () => void;
	isEditEmployeeFromView: () => void;
};

const ViewEmployee = (props: Props) => {
	const navigate = useNavigate();
	const {
		onCancel,
		isViewOnlyEmployeeDetails,
		isEditEmployeeFromView,
		fetchEmployees,
	} = props;

	const [searchParams] = useSearchParams();
	const [employeeDetails, setEmployeeDetails] = useState<any>();
	const [activeKey, setActiveKey] = useState('employmentDetails');
	const [employeeId, setEmployeeId] = useState<string>('');
	const [isEmployeeSalaryExists, setIsEmployeeSalaryExists] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [year, setYear] = useState(dayjs().year());
	const [month, setMonth] = useState(new Date().getMonth() + 1);

	const permissions = useContext(PermissionContext).allowedPermissions;

	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	const onYearChange = (selectedYear: any) => {
		setYear(selectedYear.year());
	};
	const onMonthChange = (month: number) => {
		setMonth(month);
	};

	useEffect(() => {
		const employeeIdFromSearchParams = searchParams.get('employeeId');
		if (employeeIdFromSearchParams) {
			getEmployeeDetail(employeeIdFromSearchParams);
			getSalaryDetails(employeeIdFromSearchParams);
		}
	}, []);

	const getEmployeeDetail = async (employeeId: string) => {
		try {
			setIsLoading(true);
			const employeeData = await employeeApi.getEmployeeDetailsById(
				employeeId
			);
			setEmployeeDetails(employeeData?.data?.data);
			setEmployeeId(employeeData?.data?.data?.employeeId);
		} catch (error: any) {
			let message = 'Something went wrong in fetching employee details';
			if (error.response.data.message) {
				message = error.response.data.message;
			}
			navigate('/');
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const getSalaryDetails = async (employeeId: string) => {
		try {
			const salaryData = await salaryApi.getEmployeeSalary({
				employeeId,
			});
			if (salaryData?.data?.data?.length > 0) {
				setIsEmployeeSalaryExists(true);
			}
		} catch (error: any) {
			let message =
				'Something went wrong in fetching employee salary details';
			toastText(message, 'error');
		}
	};

	let items = [
		{
			key: 'employmentDetails',
			label: 'Employment',
			children: (
				<EmployeeDetails
					changeTab={changeTab}
					onCancel={onCancel}
					employeeId={employeeId}
					employeeData={employeeDetails}
					fetchEmployees={fetchEmployees}
					getEmployeeDetail={(employeeId: string) => {
						getEmployeeDetail(employeeId);
					}}
				/>
			),
		},
		{
			key: 'taxationDetails',
			label: 'Taxation',
			children: <TaxationDetails employeeData={employeeDetails} />,
		},
		{
			key: 'salaryDetails',
			label: 'Salary',
			children: (
				<SalaryDetails
					employeeId={employeeId}
					setIsEmployeeSalaryExists={setIsEmployeeSalaryExists}
					isViewMode={true}
				/>
			),
		},
		{
			key: 'paymentInfo',
			label: 'Payment Info',
			children: <PaymentDetails employeeId={employeeId}  />,
		},
		{
			key: 'documents',
			label: 'Documents',
			children: <Documents isViewMode={true} />,
		},
	];

	if (!permissions.includes('Add_Employment')) {
		items = items.filter(
			(item) =>
				item.key !== 'employmentDetails' &&
				item.key !== 'taxationDetails'
		);
	}

	if (!permissions.includes('Add_Salary')) {
		items = items.filter((item) => item.key !== 'salaryDetails');
	}

	if (!permissions.includes('Add_Payment_Info')) {
		items = items.filter((item) => item.key !== 'paymentInfo');
	}

	const handleEditEmployee = () => {
		const employeeIdFromSearchParams = searchParams.get('employeeId');
		onCancel();
		navigate(`/employees?employeeId=${employeeIdFromSearchParams}`);
		isEditEmployeeFromView();
	};

	return (
		<>
			<Modal
				open={isViewOnlyEmployeeDetails}
				width={'100%'}
				onCancel={onCancel}
				className="preview-container"
				closable={false}
				style={{ borderRadius: 0 }}
				footer={[]}
			>
				{isLoading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '90vh',
						}}
					>
						<LoadingOutlined
							style={{ fontSize: '100px', color: '#584495' }}
						/>
					</div>
				) : (
					<div>
						<Row className={styles['preview-container__top']}>
							<div
								className={
									styles['preview-container__top-title']
								}
							>
								<b>Employee Profile</b>
							</div>
							<div
								className={
									styles['preview-container__top-close']
								}
								onClick={() => {
									setEmployeeId('');
									onCancel();
								}}
							>
								<CloseSvg />
							</div>
						</Row>
						<div className={styles['preview-container__content']}>
							<Row
								className={
									styles[
										'preview-container__content--new-employee-header'
									]
								}
							>
								<Col
									span={18}
									className={
										styles[
											'preview-container__content--new-employee-header-first'
										]
									}
								>
									Employee ID :{' '}
									<span
										className={
											styles[
												'preview-container__content--new-employee-header-first--id'
											]
										}
									>
										{employeeId}
									</span>
								</Col>
								<Col span={6}>
									<div style={{ textAlign: 'right' }}>
										<Button
											type="default"
											className={styles['edit-button']}
											onClick={handleEditEmployee}
										>
											<EditOutlined />
											Edit
										</Button>
									</div>
								</Col>
							</Row>

							<div
								className={
									styles[
										'preview-container__content--new-employee-header-tabs'
									]
								}
							>
								<Tabs
									defaultActiveKey={activeKey}
									activeKey={activeKey}
									items={items}
									onChange={(key) => changeTab(key)}
									style={{
										fontSize: '18px',
										marginBottom: '40px',
									}}
								/>

								{/* Add Edit button here */}
							</div>
						</div>
					</div>
				)}
			</Modal>
		</>
	);
};

export default ViewEmployee;
