import { shiftsApi } from 'Api/shifts';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import TableActionHeader from 'components/Global/TableActionHeader';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { useContext, useEffect, useState } from 'react';
import { checkPermission, toastText } from 'utils/utils';
import CreateShiftModal from './CreateShiftModal';
import ShiftTable from './ShiftTable';
import dayjs from 'dayjs';
import { holidayApi } from 'Api/masters/holiday';

type Props = {};

function ShiftsComponent(props: Props) {
	const permissionContext = useContext(PermissionContext);

	const [isCreateShiftModalOpen, setIsCreateShiftModalOpen] = useState(false);
	const [isViewShiftModalOpen, setIsViewShiftModalOpen] = useState(false);
	const [editShift, setEditShift] = useState<any>(null);
	const [shiftData, setShiftData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isShiftDeleteModalOpen, setIsShiftDeleteModalOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [holidays, setHolidays] = useState<any[]>([]);
	const buttons = [
		{
			text: 'Add Shift',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setIsCreateShiftModalOpen(true);
			},
			disabled: false,
		},
	];

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const closeCreateShiftModal = () => {
		setIsCreateShiftModalOpen(false);
		setIsViewShiftModalOpen(false);
		setEditShift(null);
	};

	const openCreateShiftModal = () => {
		setIsCreateShiftModalOpen(true);
	};
	const openViewShiftModal = () => {
		setIsViewShiftModalOpen(true);
	};
	const isViewPermission = checkPermission(permissionContext.permissions, {
		permissionName: 'Time Shift',
		permission: ['view'],
	});

	const isAddPermission = checkPermission(permissionContext.permissions, {
		permissionName: 'Time Shift',
		permission: ['add'],
	});

	const getHolidays = async () => {
		try {
			const currentYear = dayjs().year();
			const response = await holidayApi.getHolidaysForCurrentYear(
				currentYear
			);
	
			setHolidays(response.data.data);
		} catch (err) {
			toastText('Failed to fetch holidays.', 'error');
		}
	};

	const fetchShifts = async () => {
		try {
			setIsLoading(true);
			const query = {
				page: currentPage,
				limit: pageSize,
				sortBy: sortField,
				sortOrder: sortOrder,
			};
			const response = await shiftsApi.getShifts(query);
			setShiftData(response.data.data);
			setTotalRecords(response.data.total);
		} catch (err: any) {
			const message =
				err?.response?.data?.message ||
				'Something went wrong in fetching shifts';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const deleteHandler = async () => {
		if (editShift) {
			try {
				setIsDeleteLoading(true);
				const apiRes = await shiftsApi.deleteShift(editShift.id);
				if (totalRecords > 1 && totalRecords % pageSize === 1) {
					setCurrentPage(currentPage - 1);
				}
				closeDeleteShiftModal();
				fetchShifts();
				const message =
					apiRes?.data?.message || 'Shift deleted successfully';
				toastText(message, 'success');
			} catch (error: any) {
				const errorMessage =
					error.response?.data?.message ||
					'Something went wrong in delete shift';
				toastText(errorMessage, 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	const handleOk = () => {
		setIsShiftDeleteModalOpen(false);
		setEditShift(null);
	};

	const closeDeleteShiftModal = () => {
		setIsShiftDeleteModalOpen(false);
		setEditShift(null);
	};
	useEffect(() => {
		fetchShifts();
	}, [pageSize, currentPage, sortField, sortOrder]);

	useEffect(() => {
		getHolidays();
	}, []);
	return (
		<>
			<div>
				<TableActionHeader title={'Employees'}>
					<div>
						{isAddPermission && <Buttons buttons={buttons} />}
					</div>
				</TableActionHeader>
			</div>
			{isViewPermission && (
				<ShiftTable
					tableData={shiftData}
					totalRecords={totalRecords}
					currentPage={currentPage}
					pageSize={pageSize}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					openCreateShiftModal={openCreateShiftModal}
					openViewShiftModal={openViewShiftModal}
					setEditShift={setEditShift}
					setIsShiftDeleteModalOpen={setIsShiftDeleteModalOpen}
					fetchShifts={fetchShifts}
					holidays={holidays}
				/>
			)}
			<CreateShiftModal
				isModalOpen={isCreateShiftModalOpen || isViewShiftModalOpen}
				closeModal={closeCreateShiftModal}
				editShift={editShift}
				fetchShifts={fetchShifts}
				isViewOnly={isViewShiftModalOpen}
			/>
			<ConfirmDelete
				handleCancel={closeDeleteShiftModal}
				handleOk={handleOk}
				isModalOpen={isShiftDeleteModalOpen}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading}
			/>
		</>
	);
}

export default ShiftsComponent;
