import { deleteApi, getApi, postApi, putApi } from 'apis';

const getLeaveManagement = async (params: any) => {
	return await getApi('/leaveManagement', params);
};
const addConfigurationRoles = async (
	leaveManagementId: string,
	configurationRules: any
) => {
	return await postApi(
		`/leaveManagement/configuration/${leaveManagementId}`,
		configurationRules
	);
};

const getLeaveConfigurations = async (leaveManagementId: string) => {
	return await getApi(`/leaveManagement/configuration/${leaveManagementId}`);
};

const getLeaveTypeByEmployeeId = async (employeeId: string) => {
	return await getApi(`/leaveManagement/leaveType/${employeeId}`);
};

const addLeaveManagement = async (leaveManagementData: any) => {
	return await postApi(`/leaveManagement/create`, leaveManagementData);
};

const editLeaveManagement = async (
	leaveManagementId: string,
	leaveManagementData: any
) => {
	return await putApi(
		`/leaveManagement/update/${leaveManagementId}`,
		leaveManagementData
	);
};

const updateLeaveManagementStatus = async (data: any) => {
	return await putApi(`/leaveManagement/status/`, data);
};

const editLeaveConfiguration = async (
	leaveManagementId: string,
	configurationRules: any
) => {
	return await putApi(
		`/leaveManagement/configuration/update/${leaveManagementId}`,
		configurationRules
	);
};

const deleteLeaveManagement = async (leaveManagementId: string) => {
	console.log('leaveManagementId: ', leaveManagementId);
	return await deleteApi(`/leaveManagement/delete/${leaveManagementId}`);
};

export const leaveManagementApi = {
	getLeaveManagement,
	addConfigurationRoles,
	getLeaveConfigurations,
	getLeaveTypeByEmployeeId,
	addLeaveManagement,
	editLeaveManagement,
	editLeaveConfiguration,
	updateLeaveManagementStatus,
	deleteLeaveManagement,
};
