import { Button, Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
//import './index.scss';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { payrollApi } from 'Api/payroll';
import { useSelector } from 'react-redux';
import { bankMapping } from 'utils/mappings';
import { decrypt, formatNumber, invalidText, toastText } from 'utils/utils';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	handleProceedToPayment: () => void;
	payrollId: string;
	selectedBankId: string;
};

const AddPreviewModal = (props: Props) => {
	const { isOpen, handleCancel, payrollId, selectedBankId } = props;

	console.log('selectedBankId: ', selectedBankId);

	const { data } = useSelector((state: any) => state?.userProfile);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [employeeData, setEmployeeData] = useState([]);

	const handleDownloadBankFile = () => {
		console.log('Downloading bank file...');
	};

	const handleProceedToPayment = async () => {
		try {
			setIsButtonLoading(true);
			const paymentIds = employeeData.map((item: any) => item.id);
			console.log('employeeData: ', employeeData);
			console.log('paymentIds: ', paymentIds);
			if (payrollId) {
				const response = await payrollApi.proceedToPayment({
					payrollId: payrollId,
					companyBankId: selectedBankId,
					paymentIds,
				});
				console.log('Payment response: ', response);
				fetchEmployeeList(payrollId);
			} else {
				toastText('Something went wrong in getting payrollId', 'error');
			}
		} catch (err: any) {
			let message = 'Something went wrong in bank transfer';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsButtonLoading(false);
		}
	};

	const fetchEmployeeList = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await payrollApi.getPaymentForPayrollDetails(id);
			setEmployeeData(response.data.data);

			console.log('Response: ', response);
		} catch (err: any) {
			let message = 'Something went wrong in fetching employee details';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (payrollId && isOpen) {
			fetchEmployeeList(payrollId);
		}
	}, [payrollId, isOpen]);

	return (
		<Modal
			open={isOpen}
			closable={false}
			onCancel={handleCancel}
			// width={800}
			style={{
				minWidth: '1300px',
			}}
			footer={
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<Button key="back" onClick={handleCancel}>
						Back
					</Button>
					<Button
						key="proceed"
						type="primary"
						onClick={handleProceedToPayment}
						style={{ marginLeft: '10px' }}
						loading={isButtonLoading}
					>
						Proceed to Payment
					</Button>
				</div>
			}
		>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<Title level={4}>
						<span>
							Please review the Payment details and kindly click
							on Proceed for Payment to initiate the automatic
							Fund transfers
						</span>
					</Title>
					<div>
						<Button
							icon={<CloseOutlined />}
							onClick={handleCancel}
						/>
					</div>
				</div>
				<div
					className={styles['modal-body']}
					style={{ marginBottom: 0 }}
				>
					<Table
						dataSource={employeeData}
						rowKey="id"
						pagination={false}
						style={{ marginTop: 2 }}
						loading={isLoading}
					>
						<Column
							title="Employee Name"
							dataIndex="employeeName"
							key="employeeName"
							className="bg-white"
							width={25}
						/>
						<Column
							title="Employee Code"
							dataIndex="employeeCode"
							key="employeeCode"
							className="bg-white"
							width={10}
						/>
						<Column
							title="Bank"
							dataIndex="bank"
							key="bank"
							className="bg-white"
							width={15}
							render={(value: string) => {
								console.log('Value', value);
								return bankMapping[value]?.name ?? '';
							}}
						/>
						<Column
							title="Bank Account Number"
							dataIndex="accountNumber"
							key="accountNumber"
							className="bg-white"
							width={15}
							render={(value: string) => {
								console.log('value: ', value);
								return !invalidText(value)
									? decrypt(value)
									: '';
							}}
						/>
						<Column
							title="Sort Code"
							dataIndex="sortCode"
							key="sortCode"
							className="bg-white"
							width={5}
						/>
						<Column
							title="Amount"
							dataIndex="amount"
							key="amount"
							className="bg-white"
							width={10}
							render={(value: string) => {
								return formatNumber(
									Number(value),
									data?.baseCurrency
								);
							}}
						/>
						<Column
							title="Account Verified"
							dataIndex="accountVerified"
							key="accountVerified"
							className="bg-white"
							width={25}
							render={(value: string) => {
								return (
									<span
										style={{
											display: 'flex',
											width: '100%',
											justifyContent: 'center',
										}}
									>
										{value ? (
											<CheckOutlined
												style={{
													color: 'green',
												}}
											/>
										) : (
											<CloseOutlined
												style={{
													color: 'red',
												}}
											/>
										)}
									</span>
								);
							}}
						/>
						<Column
							title="Status"
							dataIndex="status"
							key="status"
							className="bg-white"
							width={5}
							render={(value: string) => {
								return (
									<span
										className={
											value === 'FAILED'
												? 'red'
												: value === 'SUCCESS'
												? 'green'
												: 'yellow'
										}
									>
										{value}
									</span>
								);
							}}
						/>
					</Table>
				</div>
			</div>
		</Modal>
	);
};

export default AddPreviewModal;
