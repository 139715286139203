import { timeLogsApi } from 'Api/timeLogs';
import { Alert, Badge, Card, Modal, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputHoursField from 'components/InputHoursField';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	checkSunday,
	checkHoliday,
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
import { UploadOutlined, WarningOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import axios from 'axios';
import { getApi } from 'apis';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedShift: string;
	selectedShiftDetails: any;
	fetchShifts: any;
	shiftStartDate: any;
	holidays: any;
	shiftEndDate: string;
};

const AddLogsModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		selectedShift,
		selectedShiftDetails,
		fetchShifts,
		shiftStartDate,
		shiftEndDate,
		holidays,
	} = props;

	const dispatch = useDispatch<AppDispatch>();

	const [date, setDate] = useState<any>(dayjs());

	const isSunday = checkSunday(date);
	const isHoliday = checkHoliday(date, holidays);
	// const [selectedJobGrade, setSelectedJobGrade] = useState('');
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTimeLogsLoading, setIsTimeLogsLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [employeeHours, setEmployeeHours] = useState<any>([]);
	const [uploadedFile, setUploadedFile] = useState<any>(null);
	const [defaultHours, setDefaultHours] = useState<any>({
		regularHours:
			isSunday || isHoliday
				? 0
				: selectedShiftDetails.defaultRegularHours,
		overtimeHours: selectedShiftDetails.defaultOverTimeHours,
		timeShiftHours: timeStringToMinutes('00:00'),
		totalHours:
			selectedShiftDetails.defaultRegularHours +
			selectedShiftDetails.defaultOverTimeHours,
	});

	const [fetchedFile, setFetchedFile] = useState<any>(null);

	const handleDate = (value: string) => {
		setDate(value);
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		let regularHours = timeStringToMinutes('00:00');
		let overtimeHours = timeStringToMinutes('00:00');
		let timeShiftHours = timeStringToMinutes('00:00');
		let totalHours = timeStringToMinutes('00:00');

		let _data = employeeHours;

		const totalRow = employeeHours.pop();
		_data = _data.map((log: any, index: number) => {
			if (
				newSelectedRowKeys.includes(log.employeeId) &&
				log.employeeId !== 'total'
			) {
				return {
					...log,
					attendanceStatus: 'PRESENT',
					regularHours:
						log.regularHours === '00:00'
							? minutesToTimeString(
									selectedShiftDetails.defaultRegularHours
							  )
							: log.regularHours,
					overtimeHours:
						log.overtimeHours === '00:00'
							? minutesToTimeString(
									selectedShiftDetails.defaultOverTimeHours
							  )
							: log.overtimeHours,
					timeShiftHours:
						log.timeShiftHours === '00:00'
							? '00:00'
							: log.timeShiftHours,
					totalHours:
						log.totalHours === '00:00'
							? minutesToTimeString(
									selectedShiftDetails.defaultRegularHours +
										selectedShiftDetails.defaultOverTimeHours
							  )
							: log.totalHours,
				};
			} else {
				if (regularHours < 0) {
					regularHours = 0;
				}

				return {
					...log,
					attendanceStatus: 'ABSENT',
					regularHours: '00:00',
					overtimeHours: '00:00',
					timeShiftHours: '00:00',
					totalHours: '00:00',
				};
			}
		});

		_data.forEach((item: any) => {
			regularHours += isSunday
				? 0
				: timeStringToMinutes(item.regularHours);
			overtimeHours += timeStringToMinutes(item.overtimeHours);
			timeShiftHours += timeStringToMinutes(item.timeShiftHours);
			totalHours += timeStringToMinutes(item.totalHours);
		});

		totalRow.regularHours = minutesToTimeString(regularHours);
		totalRow.overtimeHours = minutesToTimeString(overtimeHours);
		totalRow.timeShiftHours = minutesToTimeString(timeShiftHours);
		totalRow.totalHours = minutesToTimeString(totalHours);

		_data.push(totalRow);
		setDefaultHours(_data);
		setEmployeeHours(_data);

		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record: any) => ({
			disabled: record.isTotal || record.isOnLeave,
		}),
	};

	const handleLogChange = (id: string, name: string, value: any) => {
		let regularHours = 0;
		let timeShiftHours = 0;
		let overtimeHours = 0;
		let totalHours = 0;

		const _data = employeeHours.map((item: any, index: number) => {
			if (item.employeeId === id) {
				regularHours += timeStringToMinutes(
					name === 'regularHours' ? value : item.regularHours
				);
				timeShiftHours += timeStringToMinutes(
					name === 'timeShiftHours' ? value : item.timeShiftHours
				);
				overtimeHours += timeStringToMinutes(
					name === 'overtimeHours' ? value : item.overtimeHours
				);
				totalHours += timeStringToMinutes(
					name === 'totalHours' ? value : item.totalHours
				);
				return {
					...item,
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					[name]: value,
				};
			} else if (index === employeeHours.length - 1) {
				return {
					...item,
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					regularHours: isSunday
						? '00:00'
						: minutesToTimeString(regularHours),
					timeShiftHours: minutesToTimeString(timeShiftHours),
					overtimeHours: minutesToTimeString(overtimeHours),
					totalHours: minutesToTimeString(totalHours),
				};
			} else {
				regularHours += isSunday
					? 0
					: timeStringToMinutes(item.regularHours);
				timeShiftHours += timeStringToMinutes(item.timeShiftHours);
				overtimeHours += timeStringToMinutes(item.overtimeHours);
				totalHours += timeStringToMinutes(item.totalHours);
				return {
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					...item,
				};
			}
		});

		setEmployeeHours(_data);
	};

	const fetchTimeLogs = async () => {
		try {
			setIsTimeLogsLoading(true);
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				// jobGrade: selectedJobGrade,
				shiftId: selectedShift,
			};

			const response = await timeLogsApi.getDefaultTimeLogs(query);

			const defaultSelectedKeys = response.data.data
				.filter(
					(item: any) =>
						item.attendanceStatus === 'PRESENT' && !item.isOnLeave
				) // Replace with your condition
				.map((item: any) => item.employeeId);

			setEmployeeHours(response.data.data);
			setSelectedRowKeys(defaultSelectedKeys);
		} catch (err) {
			console.log('Err: ', err);
		} finally {
			setIsTimeLogsLoading(false);
		}
	};

	const validateEmployeeHours = () => {
		let invalidData = false;
		const _data = employeeHours.map((item: any) => {
			let data = { ...item };
			if (item.attendanceStatus === 'ABSENT') {
				if (item.regularHours !== '00:00') {
					data['invalidRegularHours'] = true;
				}
				if (item.overtimeHours !== '00:00') {
					data['invalidOvertimeHours'] = true;
				}
				if (item.timeShiftHours !== '00:00') {
					data['invalidTimeShiftHours'] = true;
				}
			} else if (item.attendanceStatus === 'PRESENT') {
				if (
					!isSunday &&
					!isHoliday &&
					!item.isOnLeave &&
					item.regularHours === '00:00'
				) {
					data['invalidRegularHours'] = true;
				}
			}

			// Check if any invalid field is true
			if (
				data.invalidRegularHours ||
				data.invalidOvertimeHours ||
				data.invalidTimeShiftHours
			) {
				invalidData = true;
			}
			return data;
		});
		setEmployeeHours(_data);
		return invalidData;
	};

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		if (date) {
			fetchTimeLogs();
		}
	}, [date]);

	useEffect(() => {
		if (isOpen) {
			fetchUploadedFile();
		}
	}, [isOpen, date]);

	const handleSubmit = async () => {
		try {
			setIsUpdateLoading(true);

			const data = {
				date: dayjs(date).format('YYYY-MM-DD'),
				shiftId: selectedShift,
				employeeHoursData: employeeHours
					.filter((item: any) => item.employeeId !== 'total')
					.map((log: any) => ({
						employeeId: log.employeeId,
						regularHours: isSunday
							? 0
							: timeStringToMinutes(log.regularHours),
						timeShiftHours: timeStringToMinutes(log.timeShiftHours),
						overtimeHours: timeStringToMinutes(log.overtimeHours),
						attendanceStatus: log.attendanceStatus,
					})),
			};

			const isInvalid = validateEmployeeHours();

			if (!isInvalid) {
				await timeLogsApi.createTimeLogs(data);

				// Check if the file needs to be uploaded
				if (
					uploadedFile[0] &&
					(!fetchedFile || uploadedFile[0].name !== fetchedFile.name)
				) {
					const formData = new FormData();

					formData.append('date', data.date);
					formData.append('shiftId', data.shiftId);
					formData.append('name', uploadedFile[0].name);
					formData.append('moduleName', 'TIMELOGS');
					formData.append('file', uploadedFile[0]);
					await timeLogsApi.uploadAttachment(formData);
				}

				toastText('Time logs updated successfully', 'success');
				handleCancel();
			}
		} catch (error: any) {
			let message = 'Something went wrong in submitting time logs';

			if (error.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsUpdateLoading(false);
			fetchShifts();
		}
	};

	const fetchUploadedFile = async () => {
		try {
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				shiftId: selectedShift,
			};
			const response = await getApi('/timeLogs/attachment', query);

			if (response?.data?.data?.name) {
				const file = {
					name: response.data.data.name,
					status: 'done',
				};

				setUploadedFile([file]);
				setFetchedFile(file); // Update fetched file details
			} else {
				setUploadedFile([]);
				setFetchedFile(null); // Clear fetched file details
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};
	const uploadFile = async (options: any) => {
		const { onSuccess, onError, file, onProgress } = options;

		const formattedDate = dayjs(date).format('YYYY-MM-DD');
		const fmData = new FormData();

		fmData.append('date', formattedDate);
		fmData.append('shiftId', selectedShift);
		fmData.append('moduleName', 'TIMELOGS');
		fmData.append('file', file);

		try {
			// Simulate the file upload and update state
			setUploadedFile([file]); // Update state with the uploaded file

			// Call the API (commented out)
			// const response =
			// onSuccess(response.data);
			onSuccess(null, file);
			message.success(`${file.name} file uploaded successfully`);
		} catch (error) {
			console.error(error);
			onError(error);
			message.error(`${file.name} file upload failed.`);
		}
	};

	const uploadProps: any = {
		customRequest: uploadFile,
		showUploadList: {
			showRemoveIcon: false,
			showPreviewIcon: true,
			showDownloadIcon: false,
		},
		fileList: uploadedFile,
		onChange(info: any) {
			if (info.file.status !== 'uploading') {
				if (info.file.status === 'done') {
					setUploadedFile([info.file]);
				} else if (info.file.status === 'error') {
					// Handle error, if needed
				}
			}
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 3,
			format: (percent: number) =>
				percent && `${parseFloat(percent.toFixed(2))}%`,
		},
	};

	const buttons = [
		{
			text: 'Submit',
			isLoading: isUpdateLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: isUpdateLoading,
			onclick: () => {
				handleSubmit();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isUpdateLoading,
			onclick: () => {
				handleCancel();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];
	const badgeContent =
		isSunday && isHoliday
			? {
					text: 'Sunday and Holiday',
					tooltip:
						'All hours on Sunday and Holiday are considered overtime',
			  }
			: isSunday
			? {
					text: 'Sunday',
					tooltip: 'All hours on Sunday are considered overtime',
			  }
			: isHoliday
			? {
					text: 'Holiday',
					tooltip: 'All hours on Holiday are considered overtime',
			  }
			: null;

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{
				minWidth: '1200px',
			}}
			onCancel={handleCancel}
			footer={() => {
				return (
					<div className={`${styles['modal']} modal`}>
						<div className={styles['modal-header']}>
							<Title level={4}>
								<span>Add Time Log</span>
							</Title>
							{badgeContent && (
								<Tooltip title={badgeContent.tooltip}>
									<Tag
										color="red"
										style={{
											cursor: 'pointer',
											fontSize: '14px',
											padding: '10px 10px 5px 10px',
										}}
									>
										<WarningOutlined /> {''}{' '}
										{badgeContent.text}
									</Tag>
								</Tooltip>
							)}
							<div className={styles['modal-header-filter']}>
								<DatePickerField
									name="date"
									onChange={handleDate}
									required={false}
									value={date}
									isError={false}
									disabled={isTimeLogsLoading}
									disabledBeforeDates={dayjs(shiftStartDate)}
									disabledAfterDates={dayjs(shiftEndDate)}
									placeholder="Select Date"
								/>
								{/* <SelectDropdown
									options={jobGrades}
									onChange={(value: any) => {
										console.log('Value: ', value);
										setSelectedJobGrade(value);
									}}
									required={false}
									value={selectedJobGrade}
									isError={false}
									placeholder="Select Job Grade"
									helperText=""
									size="large"
									loading={isLoading}
									disabled={isLoading}
								/> */}
							</div>
						</div>
						<div className={styles['modal-body']}>
							<Table
								dataSource={employeeHours}
								// scroll={{ y: 'calc(100vh - 360px)' }}
								pagination={false}
								rowKey={(record) => record.employeeId}
								loading={isTimeLogsLoading}
								rowSelection={rowSelection}
								rowClassName={(record: any) => {
									return record.isTotal ? 'total-row' : '';
								}}
							>
								<Column
									title="Employee name & code"
									dataIndex="employeeName"
									key="employeeName"
									sorter={true}
									className="bg-white"
									width="24%"
								/>
								<Column
									title="Regular hours"
									dataIndex="regularHours"
									key="regularHours"
									className="bg-white"
									sorter={true}
									width="19%"
									render={(record: any, data: any) => {
										return data.isTotal ? (
											isSunday ||
											isHoliday ||
											data.isOnLeave ? (
												'00:00'
											) : (
												record
											)
										) : (
											<InputHoursField
												value={
													isSunday ||
													isHoliday ||
													data.isOnLeave
														? '00:00'
														: record
												}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'regularHours',
														value
													);
												}}
												disabled={
													data.attendanceStatus ===
														'ABSENT' ||
													data.isOnLeave ||
													isSunday === true ||
													isHoliday
												}
												label="Regular hours"
												required={true}
												name="regularHours"
												isError={
													data.invalidRegularHours
												}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										);
									}}
								/>

								<Column
									title="Overtime hours"
									dataIndex="overtimeHours"
									key="overtimeHours"
									className="bg-white"
									width="19%"
									sorter={true}
									render={(record: any, data: any) => {
										return data.isTotal ? (
											data.isOnLeave ? (
												'00:00'
											) : (
												record
											)
										) : (
											<InputHoursField
												value={
													data.isOnLeave
														? '00:00'
														: record
												}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'overtimeHours',
														value
													);
												}}
												disabled={
													data.attendanceStatus ===
														'ABSENT' ||
													data.isOnLeave
												}
												label="Overtime hours"
												required={true}
												name="overtimeHours"
												isError={
													data.invalidOvertimeHours
												}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										);
									}}
								/>
								<Column
									title="Time shift difference"
									dataIndex="timeShiftHours"
									key="timeShiftHours"
									className="bg-white"
									width="19%"
									sorter={true}
									render={(record: any, data: any) => {
										return data.isTotal ? (
											data.isHoliday || data.isOnLeave ? (
												'00:00'
											) : (
												record
											)
										) : (
											<InputHoursField
												value={record}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'timeShiftHours',
														value
													);
												}}
												disabled={
													data.attendanceStatus ===
														'ABSENT' ||
													isHoliday ||
													data.isOnLeave ||
													isSunday
												}
												label="Time Shift Difference"
												required={true}
												name="timeShiftHours"
												isError={
													data.invalidTimeShiftHours
												}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										);
									}}
								/>
								<Column
									title="Total hours"
									dataIndex="totalHours"
									key="totalHours"
									className="bg-white"
									width="19%"
									sorter={true}
									render={(record: any, data: any) => {
										const totalMinutes = data.isOnLeave
											? timeStringToMinutes('00:00')
											: timeStringToMinutes(
													isSunday || isHoliday
														? '00:00'
														: data.regularHours
											  ) +
											  timeStringToMinutes(
													data.overtimeHours
											  ) +
											  timeStringToMinutes(
													data.timeShiftHours
											  );
										return (
											<span className="color-purple">
												{' '}
												{minutesToTimeString(
													totalMinutes
												)}
											</span>
										);
									}}
								/>
							</Table>
						</div>

						<div>
							<Upload {...uploadProps}>
								<Button icon={<UploadOutlined />}>
									Click to Upload
								</Button>
							</Upload>
						</div>

						<div>
							<Buttons buttons={buttons} />
						</div>
					</div>
				);
			}}
			width={600}
		></Modal>
	);
};

export default AddLogsModal;
