import { UploadOutlined } from '@ant-design/icons';
import { Button, Table, Upload } from 'antd';
import Column from 'antd/es/table/Column';
import { useState } from 'react';
import UploadRecord from '../uploadTransferModel';
import styles from './index.module.scss';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	fetchAllPayrolls: any;
};

const PayrollHistoryTable = (props: TableProps) => {
	const [uploadRecordModal, setUploadRecordModal] = useState(false);
	const [payrollHistoryId, setPayrollHistoryId] = useState<any>(null);
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		fetchAllPayrolls,
	} = props;

	const uploadProps = {
		name: 'file',
		action: '/upload', // Replace with your API endpoint
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info: any) {
			if (info.file.status === 'done') {
				console.log(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				console.log(`${info.file.name} file upload failed.`);
			}
		},
	};

	const dataSource = [
		{
			id: 1,
			payPeriod: 'August 2024',
			approvedOn: '10/08/2024',
			paidOn: '15/08/2024',
			bankFile: 'BankFile123.pdf',
			transferReceipt: 'Receipt123.pdf',
			status: 'Payment done',
			action: 'View',
		},
	];

	const openUploadRecordModal = (historyId: string) => {
		setUploadRecordModal(true);
		setPayrollHistoryId(historyId);
	};

	const closeAllModal = () => {
		setUploadRecordModal(false);
		setPayrollHistoryId(null);
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period"
					dataIndex="payPeriod"
					key="payPeriod"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Approved On"
					dataIndex="approvedOn"
					key="approvedOn"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Paid On"
					dataIndex="paidOn"
					key="paidOn"
					sorter={false}
					className="bg-white"
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>
				<Column
					title="Bank file"
					dataIndex="bankFile"
					key="bankFile"
					sorter={false}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.bankFileLink ? (
								<a
									href={record.bankFileLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.bankFileName}
										maxLength={20}
										key={record.bankFileName}
										tooltipMessage={record.bankFileName}
										isTooltip={true}
									/>
								</a>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Transfer receipt"
					dataIndex="transferReceipt"
					key="transferReceipt"
					sorter={false}
					className="bg-white"
					render={(_: any, record: any) => (
						<div>
							<Button
								icon={<UploadOutlined />}
								type="text"
								style={{ color: '#584495' }}
								onClick={() => openUploadRecordModal(record.id)}
							>
								Upload file
							</Button>
							{record.transferReceiptLink ? (
								<a
									href={record.transferReceiptLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.transferReceiptName}
										maxLength={20}
										key={record.transferReceiptName}
										tooltipMessage={
											record.transferReceiptName
										}
										isTooltip={true}
									/>
								</a>
							) : (
								<></>
							)}
						</div>
					)}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
					className="bg-white"
					render={(status) => (
						<span style={{ color: 'green' }}>{status}</span>
					)}
				/>
				{/* <Column
					title="Action"
					dataIndex="action"
					key="action"
					sorter={false}
					className="bg-white"
					render={(_, record) => (
						<div>
							<Button
								type="text"
								icon={<EditActionSvg />}
								onClick={() => console.log('Edit', record)}
							/>
							<Button
								type="text"
								icon={
									<EyeOutlined
										style={{
											color: '#000',
											fontSize: '22px',
										}}
									/>
								}
								onClick={() => console.log('View', record)}
							/>
							<Button
								type="text"
								icon={<DeleteActionSvg />}
								onClick={() => console.log('Delete', record)}
							/>
						</div>
					)}
				/> */}
			</Table>

			{uploadRecordModal && (
				<UploadRecord
					open={uploadRecordModal}
					closeUploadRecordModal={closeAllModal}
					payrollHistoryId={payrollHistoryId}
					fetchAllPayrolls={fetchAllPayrolls}
				/>
			)}
		</div>
	);
};

export default PayrollHistoryTable;
