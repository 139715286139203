import { Space, Table } from 'antd';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/utils';

const { Column } = Table;

type Props = {
	earningData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setEditData: any;
	openDrawerHandler: any;
	setDrawerInfoHandler: any;
	showModal: any;
	isLoading: boolean;
};

const EarningTable = (props: Props) => {
	const {
		earningData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		openDrawerHandler,
		setDrawerInfoHandler,
		setEditData,
		showModal,
		isLoading,
	} = props;

	const permissions = useContext(PermissionContext);
	const { data } = useSelector((state: any) => state?.userProfile);
	// Edit user data handler
	const editDataHandler = (selectedObj: any) => {
		openDrawerHandler();
		setDrawerInfoHandler('Edit Earning');
		setEditData(selectedObj);
	};

	// Delete user data handler
	const deleteDataHandler = (userObject: any) => {
		setEditData(userObject);
		showModal();
	};
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={earningData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Earning Code"
					dataIndex="code"
					key="code"
					sorter={true}
					className="bg-white"
				/>
				{/* <Column
					title="Account Number"
					dataIndex="acctNo"
					key="acctNo"
					className="bg-white"
					sorter={true}
				/> */}
				<Column
					title="Description"
					dataIndex="description"
					key="description"
					className="bg-white"
					sorter={true}
					render={(text, record: any) =>record?.description.toUpperCase()
					}
				/>
				{/* <Column
					title="Sequence Number"
					dataIndex="seqNo"
					key="seqNo"
					className="bg-white"
					sorter={true}
				/> */}
				<Column
					title="Amount"
					dataIndex="amount"
					key="amount"
					className="bg-white"
					sorter={true}
					render={(value: string) => {
						return formatNumber(Number(value), data?.baseCurrency);
					}}
				/>
				{/* <Column
					title="Percent"
					dataIndex="percent"
					key="percent"
					className="bg-white"
					sorter={true}
				/> */}
				<Column
					title="Formula"
					dataIndex="formula"
					key="formula"
					className="bg-white"
					sorter={true}
				/>
				{/* <Column
					title="Function"
					dataIndex="function"
					key="function"
					className="bg-white"
					sorter={true}
				/> */}
				{(permissions.allowedPermissions.includes(
					'Edit_Earnings_Code'
				) ||
					permissions.allowedPermissions.includes(
						'Delete_Earnings_Code'
					)) && (
					<Column
						title="Action"
						key="action"
						className="bg-white"
						render={(_: any, data: any) => (
							<Space size={20}>
								{permissions.allowedPermissions.includes(
									'Edit_Earnings_Code'
								) ? (
									<div
										className="cursor-pointer  flex align-center justify-center"
										onClick={() => editDataHandler(data)}
									>
										<EditActionSvg />
									</div>
								) : (
									<div className="cursor-not-allowed  flex align-center justify-center">
										<EditActionSvg color="#928F8F" />
									</div>
								)}
								{permissions.allowedPermissions.includes(
									'Delete_Earnings_Code'
								) ? (
									<div
										className="cursor-pointer flex align-center justify-center"
										onClick={() => deleteDataHandler(data)}
									>
										<DeleteActionSvg />
									</div>
								) : (
									<div className="cursor-not-allowed flex align-center justify-center">
										<DeleteActionSvg color="#928F8F" />
									</div>
								)}
							</Space>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default EarningTable;
