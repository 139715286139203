import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { payrollApi } from 'Api/payroll';
import { FaFilePdf } from 'react-icons/fa';
import { toastText } from 'utils/utils';
import './index.scss';
import { viewPayrollApi } from 'Api/payrollView';

const { Column } = Table;

type Props = {
	selectedPayrollId: string;
	onlyView: boolean;
};

const PaySlipTable: React.FC<Props> = (props: Props) => {
	const { selectedPayrollId, onlyView } = props;
	const [selectionType, setSelectionType] = useState<any>('checkbox');
	const [paySlipData, setPaySlipData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
			console.log(
				`selectedRowKeys: ${selectedRowKeys}`,
				'selectedRows: ',
				selectedRows
			);
		},
		getCheckboxProps: (record: any) => ({
			disabled: record.name === 'Disabled User',
		}),
	};

	const getPaySlips = async (payrollId: string) => {
		try {
			setIsLoading(true);
			const response = await payrollApi.getPayslips(payrollId);
			if (response) {
				setPaySlipData(response.data.data);
			}
			setIsLoading(false);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const getPaySlipForView = async (payrollId: string) => {
		try {
			setIsLoading(true);
			const response = await viewPayrollApi.getAllPaySlips(payrollId);
			if (response) {
				setPaySlipData(response.data.data);
			}
			setIsLoading(false);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		if (onlyView) {
			getPaySlipForView(selectedPayrollId);
		} else {
			getPaySlips(selectedPayrollId);
		}
	}, [selectedPayrollId]);
	return (
		<>
			<div className="pay-slip-table">
				<Table
					dataSource={paySlipData}
					className="pay-slips"
					scroll={{ y: 'calc(90vh - 360px)' }}
					pagination={false}
					rowKey={(record) => record.id}
					loading={isLoading}
					// rowSelection={{
					// 	type: selectionType,
					// 	...rowSelection,
					// }}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'45%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								<span>{record.employeeName}</span> |{' '}
								<span className="color-purple">
									{record.employeeId}
								</span>
							</>
						)}
					/>

					<Column
						title="Pay Slips"
						width={'45%'}
						className="bg-white"
						dataIndex="paymentSlips"
						key="paymentSlips"
						render={(text, record: any) => (
							<>
								<a
									href={record.documentLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FaFilePdf color="#d32f2f" size={20} />{' '}
								</a>
							</>
						)}
					/>
				</Table>
			</div>
		</>
	);
};

export default PaySlipTable;
