import { napsaApi } from 'Api/napsa';
import { SideDrawerWrapper } from 'components/Global';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import PersonalLevyHeader from './Header';
import PersonalLevyTable from './PersonalLevyTable';
import AddPersonalLevySideDrawer from './AddPersonalLevySideDrawer';
import HistoryModal from './HistoryModal';
import { personalLevyApi } from 'Api/personalLevy';

type personalLevyTabProps = {
	personalLevyData: any;
	isLoading: boolean;
	showModal: () => void;
	totalRecords: number;
};
const PersonalLevyComponent = (props: personalLevyTabProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [personalLevyDetails, setPersonalLevyDetails] = useState<any>([]);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isHistoryLoading, setIsHistoryLoading] = useState(false);
	const [allPersonalLevyData, setAllPersonalLevyData] = useState<any>([]);

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const sidebarDrawerOpen = () => {
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};

	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	const closeDrawerByAnimation = () => {
		setDrawerAnimation(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	const fetchPersonalLevyData = async () => {
		try {
			setIsLoading(true);
			const personalLevyData =
				await personalLevyApi.getLatestPersonalLevyDetails();
			setPersonalLevyDetails(personalLevyData.data.data);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching personal levy Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsLoading(false);
		}
	};
	const fetchAllPersonalLevyData = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			setIsHistoryLoading(true);
			const allData = await personalLevyApi.getAllPersonalLevyDetails(
				query
			);
			const historyDetails = allData.data.data;

			setTotalRecords(allData.data.total);
			setAllPersonalLevyData(historyDetails);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText('Something went wrong in fetching history', 'error');
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsHistoryLoading(false);
		}
	};
	useEffect(() => {
		fetchPersonalLevyData();
	}, []);

	useEffect(() => {
		fetchAllPersonalLevyData();
	}, [pageSize, currentPage, sortOrder, sortField]);

	return (
		<>
			<div>
				<PersonalLevyHeader
					sidebarDrawerOpen={sidebarDrawerOpen}
					PersonalLevyExists={personalLevyDetails.length > 0}
					isLoading={isLoading}
				/>
				<PersonalLevyTable
					personalLevyData={personalLevyDetails}
					isLoading={isLoading || isHistoryLoading}
					showModal={showModal}
					totalRecords={totalRecords}
				/>
			</div>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={
						fetchPersonalLevyData.length > 0
							? 'Update Personal Levy'
							: 'Setup Personal Levy'
					}
					position="right"
					width="half"
				>
					<AddPersonalLevySideDrawer
						closeDrawerByAnimation={closeDrawerByAnimation}
						fetchPersonalLevyData={fetchPersonalLevyData}
						fetchAllPersonalLevyData={fetchAllPersonalLevyData}
					/>
				</SideDrawerWrapper>
			)}

			{isModalOpen && (
				<HistoryModal
					closeModal={closeModal}
					open={isModalOpen}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					allPersonalLevyData={allPersonalLevyData}
					isLoading={isHistoryLoading}
					fetchAllNapsaDetails={fetchPersonalLevyData}
				/>
			)}
		</>
	);
};

export default PersonalLevyComponent;
