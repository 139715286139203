import { Button } from 'antd';
import styles from './index.module.scss';
import { DownloadOutlined } from '@ant-design/icons';

type headerProps = {
	headerData: any;
	Payslip?: boolean;
};
const Header = ({ headerData, Payslip }: headerProps) => {
	return (
		<>
			<div className={styles['global-header']}>
				<div className={styles['inline-container']}>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Pay Period:</p>
						<p className={styles['label-value']}>
							{headerData?.payPeriodName}
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Pay Group:</p>
						<p className={styles['label-value']}>
							{' '}
							{headerData?.payGroupNames}
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Currency:</p>
						<p className={styles['label-value']}>
							{' '}
							{headerData?.currency}
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Department:</p>
						<p className={styles['label-value']}>
							{' '}
							{headerData?.payGroupDepartment}
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Branch:</p>
						<p className={styles['label-value']}>
							{headerData?.payGroupBranch}
						</p>
					</div>
				</div>
				{Payslip && (
					<div className={styles['download-button-container']}>
						<Button
							type="primary"
							className={styles['download-slip-button']}
						>
							<DownloadOutlined /> Download Pay Slip
						</Button>
					</div>
				)}
			</div>
		</>
	);
};

export default Header;
