import { useState } from 'react';
import { CashSvg, ChequeSvg, RightArrowSvg } from 'utils/svgs';
import Cheque from './Cheque';
import DirectDeposit from './DirectDeposit';

import styles from './index.module.scss';
import CustomCollapsePanel from 'components/Global/Collpase';

const PaymentInfo = () => {
	const [activeKey, setActiveKey] = useState<string | null>(null);
	const [isVerifiedAccount, setIsVerifiedAccount] = useState(false);
	const handleCollapseClose = () => {
		setActiveKey(null); // Close all panels
	};
	const trueVerifiedAccount = () => {
		setIsVerifiedAccount(true);
	};

	const falseVerifiedAccount = () => {
		setIsVerifiedAccount(false);
	};

	return (
		<div className={styles['form']}>
			<div className={styles['form-container']}>
				<div className={styles['form-container-head']}>
					<p className={styles['form-container-head-title']}>
						Payment Info
					</p>
				</div>

				<div className={styles.collapse}>
					<div className={styles['collapse-menu']}>
						<p className={styles['collapse-title']}>
							How would you like to pay this employee?{' '}
							<sup className={styles['collapse-required']}>*</sup>
						</p>

						<CustomCollapsePanel
							header={
								isVerifiedAccount
									? 'Direct Deposit (Verified)'
									: 'Direct Deposit (Not Verified)'
							}
							icon={<RightArrowSvg />}
							activeKey={activeKey}
							setActiveKey={setActiveKey}
							panelKey="0"
							isOpen={false}
						>
							<DirectDeposit
								onCancel={handleCollapseClose}
								falseVerifiedAccount={falseVerifiedAccount}
								trueVerifiedAccount={trueVerifiedAccount}
							/>
						</CustomCollapsePanel>

						<CustomCollapsePanel
							header="Cheque"
							icon={<ChequeSvg />}
							activeKey={activeKey}
							setActiveKey={setActiveKey}
							panelKey="1"
							isOpen={false}
						>
							<Cheque onCancel={handleCollapseClose} />
						</CustomCollapsePanel>

						<CustomCollapsePanel
							header="Cash"
							icon={<CashSvg />}
							activeKey={activeKey}
							setActiveKey={setActiveKey}
							panelKey="2"
							isOpen={false}
						>
							<div>Cash</div>
						</CustomCollapsePanel>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentInfo;
