import { PermissionContext } from 'components/Global/AuthLayout';
import ConfigurationSidebar from 'components/Global/ConfigurationSidebar';
import { FORMDATA } from 'constants/Data';
import { FC, useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { SettingsLayoutProps } from './types';

// Settings page layout
const ReportLayout: FC<SettingsLayoutProps> = (props) => {
	const { ReportMenuItems } = FORMDATA;
	const { children, onSideBarChange, selectedSidebar } = props;

	const permissionContext = useContext(PermissionContext);
	const { allowedPermissions } = permissionContext;
	const [menuItems, setMenuItems] = useState(ReportMenuItems);

	useEffect(() => {
		let _menuItems = ReportMenuItems;

		if (
			!allowedPermissions.includes('Allow_Earning_and_Deduction_Reports')
		) {
			_menuItems = _menuItems.filter(
				(item) =>
					item?.key !== 'napsa' &&
					item?.key !== 'nhima' &&
					item?.key !== 'paye'
			);
		}

		if (!allowedPermissions.includes('Allow_Payroll_Reports')) {
			_menuItems = _menuItems.filter(
				(item) => item?.key !== 'payroll-summary'
			);
		}
		if (!allowedPermissions.includes('Allow_Tax_Reports')) {
			_menuItems = _menuItems.filter((item) => item?.key !== 'p-18');
		}

		setMenuItems(_menuItems);
		console.log('_menuItems: ', _menuItems);
	}, [ReportMenuItems, permissionContext]);

	// JSX
	return (
		<div className={styles['configuration-layout']}>
			<div className={styles['configuration-layout__wrapper']}>
				<ConfigurationSidebar
					items={menuItems}
					handleSidebar={onSideBarChange}
					selectedSidebar={selectedSidebar as string}
				/>
				<div className={styles['configuration-layout__body']}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ReportLayout;
