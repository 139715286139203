import { payrollApi } from 'Api/payroll';
import { Col, Row } from 'antd';
import { TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { EmployeeLeaveImage } from 'utils/svgs';
import { toastText } from 'utils/utils';
import GlobalHeader from '../GlobalHeader';
import EmployeeLeavesTable from './Table';
import styles from './index.module.scss';

type EmployeeLeavesProps = {
	payrollId: any;
	onlyView: boolean;
	headerData: any;
};
const EmployeeLeavesComponent = (props: EmployeeLeavesProps) => {
	const [employeeLeaveSortOrder, setEmployeeLeaveSortOrder] = useState('');
	const [employeeLeaveSortField, setEmployeeLeavesortField] = useState('');
	const [employeeData, setEmployeeData] = useState<any[]>([]);
	const [employeeLeaveLoading, setEmployeeLeaveLoading] = useState(false);
	const { payrollId, onlyView , headerData} = props;

	const fetchEmployeeLeave = async () => {
		try {
			const query = {
				payrollId: payrollId,
				sortBy: employeeLeaveSortField,
				sortOrder: employeeLeaveSortOrder,
			};
			setEmployeeLeaveLoading(true);
			const leaveRequest = await payrollApi.getEmployeesLeaves(query);
			setEmployeeData(leaveRequest.data.data);
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setEmployeeLeaveLoading(false);
		}
	};

	useEffect(() => {
		if (onlyView) {
			//db call to get leave
		} else {
			fetchEmployeeLeave();
		}
	}, [employeeLeaveSortField, employeeLeaveSortOrder, payrollId]);
	const employeeTableChangeHandler = (filters: any, sorter: TableSorter) => {
		setEmployeeLeaveSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setEmployeeLeavesortField(sorter.field);
	};

	const handleCheckboxChange = (id: string) => {
		const updatedData = employeeData.map((item) => {
			if (item.id === id) {
				return {
					...item,
					deductionType:
						item.deductionType === 'deductedFromSalary'
							? 'deductedFromLeaveBalance'
							: 'deductedFromSalary',
				};
			}
			return item;
		});
		setEmployeeData(updatedData);
	};

	return (
		<>
			<Row>
				<Col span={16}>
					<div className={styles['employee-leaves__table']}>
						<GlobalHeader headerData={headerData} />
						<EmployeeLeavesTable
							isLoading={employeeLeaveLoading}
							tableChangeHandler={employeeTableChangeHandler}
							handleCheckboxChange={handleCheckboxChange}
							employeeData={employeeData}
						/>
					</div>
				</Col>
				<Col span={1}></Col>
				<Col span={7}>
					<div>
						<EmployeeLeaveImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default EmployeeLeavesComponent;
