import { payrollApi } from 'Api/payroll';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, toastText } from 'utils/utils';
import './index.scss';
import { viewPayrollApi } from 'Api/payrollView';

type Props = {
	selectedPayrollId: string;
	setEarningJSON: any;
	onlyView: boolean;
};

const TotalEarningTable: React.FC<Props> = (props: Props) => {
	const { selectedPayrollId, setEarningJSON, onlyView } = props;

	const [earningsData, setEarningsData] = useState([]);
	const [isEarningsLoading, setEarningsLoading] = useState(false);
	const [columns, setColumns] = useState<any>([]);

	const { data } = useSelector((state: any) => state?.userProfile);

	const generateColumns = (columnsData: any) => {
		const groupedColumns = columnsData.reduce((acc: any, column: any) => {
			const { parent, title } = column;
			if (!acc[parent]) {
				acc[parent] = [];
			}
			acc[parent].push({
				id: Math.random(),
				title,
				dataIndex: title,
				key: title,
				width: '150px',
			});
			return acc;
		}, {});

		for (const key in groupedColumns) {
			const children = groupedColumns[key];
			children.forEach((child: any, index: number) => {
				child.className = `children-normal-font-weight ${
					index !== children.length - 1 ? 'border-right-none' : ''
				}`;
			});
		}

		// Convert grouped data into the final format
		const convertedColumns = Object.keys(groupedColumns).map((parent) => ({
			title: parent,
			className: 'background-color-dark',
			children: groupedColumns[parent],
		}));

		// Adding additional columns as needed
		convertedColumns.unshift({
			title: '',
			className: 'background-color-dark',
			children: [
				{
					title: 'Employees',
					dataIndex: 'Employee',
					key: 'Employee',
					fixed: 'left',
					width: 250,
					className: 'children-normal-font-weight',
					render: (text: string, record: any) => (
						<span>{record.id === 'total' ? text : `${text}`}</span>
					),
				},
			],
		});

		convertedColumns.push({
			title: 'Total',
			className: 'background-color-dark',
			children: [
				{
					title: '',
					dataIndex: 'total',
					key: 'total',
					width: '150px',
				},
			],
		});

		return convertedColumns;
	};

	const fetchEarnings = async (payRollId: string) => {
		try {
			setEarningsLoading(true);
			const response = await payrollApi.getPayrollEarnings(payRollId);

			const generatedColumns = generateColumns(
				response.data.data?.columns
			);
			setEarningJSON(response.data.data?.rows);
			console.log('response.data.data?.rows', response.data.data?.rows);

			const formattedRowData = response.data.data?.rows.map(
				(item: any) => {
					const formattedItem: any = {};

					for (const key in item) {
						if (key == 'Employee') {
							formattedItem[key] = item[key];
						} else {
							// formattedItem[key] = item[key];
							formattedItem[key] = formatNumber(
								Number(item[key]),
								data?.baseCurrency
							);
						}
					}
					return formattedItem;
				}
			);

			setColumns(generatedColumns);
			setEarningsData(formattedRowData);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setEarningsLoading(false);
		}
	};
	const fetchViewEarnings = async (payRollId: string) => {
		try {
			setEarningsLoading(true);
			const response = await viewPayrollApi.getAllEmployeeEarnings(
				payRollId
			);

			const generatedColumns = generateColumns(
				response.data.data?.columns
			);
			setEarningJSON(response.data.data?.rows);
			console.log('response.data.data?.rows', response.data.data?.rows);

			const formattedRowData = response.data.data?.rows.map(
				(item: any) => {
					const formattedItem: any = {};

					for (const key in item) {
						if (key == 'Employee') {
							formattedItem[key] = item[key];
						} else {
							// formattedItem[key] = item[key];
							formattedItem[key] = formatNumber(
								Number(item[key]),
								data?.baseCurrency
							);
						}
					}
					return formattedItem;
				}
			);

			setColumns(generatedColumns);
			setEarningsData(formattedRowData);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setEarningsLoading(false);
		}
	};

	useEffect(() => {
		if (onlyView) {
			fetchViewEarnings(selectedPayrollId);
		} else {
			fetchEarnings(selectedPayrollId);
		}
	}, [selectedPayrollId]);
	return (
		<>
			<div>
				<Table
					className="total-earning-table"
					columns={columns}
					dataSource={earningsData}
					bordered
					scroll={{ y: 'calc(100vh - 550px)' }}
					pagination={false}
					rowKey={(record: any) => record.id}
					loading={isEarningsLoading}
				/>
			</div>
		</>
	);
};

export default TotalEarningTable;
