import { PermissionContext } from 'components/Global/AuthLayout';
import TableActionHeader from 'components/Global/TableActionHeader';
import ReportLayout from 'layouts/Report';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { getAllowedModule } from 'utils/utils';

interface SelectedValue {
	key: string;
}

export default function Report() {
	const context = useContext(PermissionContext);
	const navigate = useNavigate();

	const pathname = window.location.pathname;
	const pathSegments = pathname.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];

	const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);
	console.log('selectedSidebar: ', selectedSidebar);

	// Function to handle sidebar changes
	const sideBarChangeHandler = (selectedValue: SelectedValue) => {
		setSelectedSidebar(selectedValue.key);
		navigate(selectedValue.key);
	};

	useEffect(() => {
		const allowedReports = getAllowedModule(context.permissions, [
			'Earning and Deduction Reports',
			'Payroll Reports',
			'Tax Reports',
		]);
		if (lastSegment === 'reports') {
			if (allowedReports === 'Earning and Deduction Reports') {
				navigate(`/reports/napsa`);
			} else if (allowedReports === 'Payroll Reports') {
				navigate(`/reports/payroll-summary`);
			} else if (allowedReports === 'Tax Reports') {
				navigate(`/reports/p-18`);
			} else {
				navigate(`/`);
			}
		}
		setSelectedSidebar(lastSegment);
	}, [lastSegment]);

	return (
		<>
			<TableActionHeader title={'Reports'} />
			<div className={styles['report']}>
				<ReportLayout
					onSideBarChange={sideBarChangeHandler}
					selectedSidebar={selectedSidebar}
				>
					<Outlet />
				</ReportLayout>
			</div>
		</>
	);
}
