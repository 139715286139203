import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import dayjs from 'dayjs';
import ReportPopOver from '../../ReportPopOver';
import Ellipse from 'components/Global/Ellipse';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type tableProps = {
	openUploadRecordModal: (compensationRecordId: string) => void;
	calculateCompensationCurrentPage: number;
	calculateCompensationPageSize: number;
	calculateCompensationTotalRecords: number;
	calculateCompensationIsLoading: boolean;
	calculateCompensationData: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const TableComponent = (props: tableProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const {
		openUploadRecordModal,
		calculateCompensationCurrentPage,
		calculateCompensationPageSize,
		calculateCompensationTotalRecords,
		calculateCompensationIsLoading,
		calculateCompensationData,
		tableChangeHandler,
	} = props;

	console.log('calculateCompensationData: ', calculateCompensationData);
	return (
		<Table
			dataSource={calculateCompensationData}
			rowKey={(record: any) => record.id}
			loading={calculateCompensationIsLoading}
			pagination={{
				total: calculateCompensationTotalRecords,
				current: calculateCompensationCurrentPage,
				pageSize:calculateCompensationPageSize,
				showSizeChanger: false,
				pageSizeOptions: [10, 20, 50, 100, 200],
			}}
			onChange={tableChangeHandler}
		>
			<Column
				title="Created Date"
				dataIndex="createdAt"
				key="createdAt"
				render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
				sorter={true}
				width={'15%'}
					className="bg-white"
			/>
			{/* <Column
				title="Currency"
				dataIndex="currency"
				key="currency"
				width={'20%'}
			/> */}
			<Column
				title="Calculated by"
				dataIndex="calculatedBy"
				key="calculatedBy"
				render={(text: any) => text}
				width={'15%'}
					className="bg-white"
			/>
		 <Column
				title="Year"
				dataIndex="year"
				key="year"
				render={(text: any) => text}
				width={'15%'}
					className="bg-white"
			/>
			<Column
				title="Reports"
				dataIndex="report"
					className="bg-white"
				key="report"
				width={'20%'}
				render={(_: any, record: any) => (
					<ReportPopOver
						content={record.CompensationReport}
						reportType="Compensation"
					/>
				)}
			/>
			{/* <Column
				title="Records"
				dataIndex=""
				key="docName"
				render={(_: any, record: any) => (
					<div
						style={{
							display: 'flex',
							gap: '2rem',
							alignItems: 'center',
						}}
					>
						{isAdd && (
							<Buttons
								buttons={[
									{
										text: 'Upload',
										isLoading: false,
										className: 'btn-blue',
										isSubmit: false,
										disabled: false,
										onclick: () => {
											openUploadRecordModal(record.id);
										},
										fontSize: '1.5rem',
										minWidth: '7rem',
										minHeight: '2rem',
									},
								]}
							/>
						)}
						{record.recordUrl ? (
							<a
								href={record.recordUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Ellipse
									message={record.recordName}
									maxLength={20}
									key={record.recordName}
									tooltipMessage={record.recordName}
									isTooltip={true}
								/>
							</a>
						) : (
							<></>
						)}
					</div>
				)}
				width={'30%'}
			/>	 */}
		</Table>
	);
};

export default TableComponent;
