/* eslint-disable react-hooks/exhaustive-deps */
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import React, { useContext, useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import Styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import GlobalReportTable from '../GlobalReportTable';
import GeneratePayeModal from './GeneratePayeModal';
import { reportApis } from 'Api/reports';
import { PermissionContext } from 'components/Global/AuthLayout';

const PayeReportComponent = () => {
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [payeReportData, setPayeReportData] = useState<any[]>([]);

	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes(
		'Add_Earning_and_Deduction_Reports'
	);
	const buttons = [
		{
			text: 'Generate PAYE',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setModalOpen(true);
			},
			disabled: false,
		},
	];

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const cancelModal = () => {
		setModalOpen(false);
	};

	const fetchPayeReports = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			const payeReportData = await reportApis.getPayeReports(query);
			setPayeReportData(payeReportData.data.data);
			setTotalRecords(0);
		} catch (err: any) {
			let message = 'Something went wrong in fetching PAYE reports.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchPayeReports();
	}, [currentPage, sortField, sortOrder, pageSize]);

	return (
		<>
			<div className={Styles['container']}>
				<div className={Styles['paye-header']}>
					<p>PAYE</p>
					{check && <Buttons buttons={buttons} />}
				</div>
				<GlobalReportTable
					ReportData={payeReportData}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
				/>
			</div>
			{modalOpen && (
				<>
					<GeneratePayeModal
						open={modalOpen}
						cancelGenerateModal={cancelModal}
						fetchPayeReportDetails={fetchPayeReports}
					/>
				</>
			)}
		</>
	);
};

export default PayeReportComponent;
