import React, { useContext, useEffect, useState } from 'react';
import { List, Typography, Progress } from 'antd';
import {
	CheckCircleOutlined,
	LoadingOutlined,
	RightOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import Buttons from 'components/Global/Buttons';
import { payrollApi } from 'Api/payroll';
import { toastText } from 'utils/utils';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchProfileAction } from '../../../../redux/actions/profileAction';

const { Title, Text, Link } = Typography;

const PayrollSetupUI = () => {
	const [steps, setSteps] = useState<any>([]);
	const [isCompanyWizardLoading, setIsCompanyWizardLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const profileData = useSelector((state: any) => state?.userProfile?.data);

	const permissionContext = useContext(PermissionContext);
	const { allowedPermissions } = permissionContext;

	const handleNavigation = (key: string) => {
		navigate(`/settings/payroll-settings/${key}`);
	};

	const completedSteps = steps?.filter((step: any) => step.completed).length;

	console.log('Completed Steps:', completedSteps);

	const getCompanyWizard = async () => {
		try {
			setIsCompanyWizardLoading(true);
			const response = await payrollApi.getCompanyWizard();
			if (response.data) {
				const { data } = response.data;
				setSteps(data);
			}
		} catch (error) {
			toastText('Something went wrong in getting company setup', 'error');
		} finally {
			setIsCompanyWizardLoading(false);
		}
	};

	const isStepFullyCompleted = (step: any) => {
		if (step.subSteps) {
			return step.subSteps.every((subStep: any) => subStep.completed);
		}
		return step.completed;
	};

	const hasPermission = (key: string) => {
		const permissionMap: { [key: string]: string } = {
			'company-setup': 'Allow_Company_Setup',
			'pay-period': 'Allow_Pay_Period',
			'statutory-components': 'Allow_Statutory_Components',
			'pay-group': 'Allow_Pay_Group',
			'earning-code': 'Allow_Earnings_Code',
			'deduction-code': 'Allow_Deduction_Code',
			'mid-month-pay': 'Allow_Mid-Month_Pay_Setup',
		};

		return allowedPermissions.includes(permissionMap[key]);
	};

	useEffect(() => {
		getCompanyWizard();
		dispatch(fetchProfileAction());
	}, []);
	const name = `${profileData?.firstName} ${profileData?.lastName}`;
	return (
		<>
			{isCompanyWizardLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '250px',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : (
				<div className={styles.container}>
					<Title level={3} className={styles.welcomeTitle}>
						Welcome ! {name}👋
					</Title>
					<Text className={styles.welcomeDescription}>
						Set up your organization before running your payroll.
					</Text>
					<div className={styles.setupProgressContainer}>
						<div className={styles.progressHeader}>
							<div>
								<Title
									level={4}
									className={styles.progressTitle}
								>
									Get started with Wageworks payroll
								</Title>
								<Text className={styles.progressDescription}>
									Follow these steps to ensure a smooth and
									efficient payroll process.
								</Text>
							</div>
							<div className={styles.progressStatus}>
								<Text>{`${completedSteps} / ${steps.length} Completed`}</Text>
								<Progress
									percent={
										(completedSteps / steps.length) * 100
									}
									showInfo={false}
									strokeColor="#584495"
									trailColor="#dfd7f3"
									size="small"
								/>
							</div>
						</div>

						<List
							dataSource={steps}
							renderItem={(step: any) => {
								const isCompleted = isStepFullyCompleted(step);
								const hasStepPermission = hasPermission(
									step.key
								);
								return (
									<List.Item
										className={`${styles.stepItem} ${
											isCompleted ? styles.completed : ''
										}`}
									>
										<div className={styles.stepIcon}>
											{isCompleted ? (
												<CheckCircleOutlined />
											) : (
												<div
													className={
														styles.uncheckedIcon
													}
												/>
											)}
										</div>
										<div className={styles.stepContent}>
											<p>{step.title}</p>
											{step.subSteps && (
												<List
													dataSource={step.subSteps}
													renderItem={(
														subStep: any
													) => (
														<div
															className={
																styles.subStep
															}
														>
															{subStep.completed ? (
																<CheckCircleOutlined
																	style={{
																		color: '#52c41a',
																	}}
																/>
															) : (
																<div
																	style={{
																		width: '1.5rem',
																		height: '1.5rem',
																		borderRadius:
																			'50%',
																		border: '2px solid #d9d9d9',
																		marginRight:
																			'4px',
																	}}
																/>
															)}
															<Text>
																{subStep.title}
															</Text>
														</div>
													)}
												/>
											)}
											{isCompleted &&
												hasStepPermission && (
													<Link
														className={
															styles.viewDetails
														}
														onClick={() =>
															handleNavigation(
																step.key
															)
														}
													>
														View details{' '}
														<RightOutlined />
													</Link>
												)}
										</div>
										{isCompleted ? (
											<div
												className={
													styles.completedButton
												}
											>
												{/* <CheckCircleOutlined />{' '} */}
												<p>Completed</p>
											</div>
										) : (
											<Buttons
												buttons={[
													{
														text: 'Complete Now',
														isLoading: false,
														className:
															'btn-secondary',
														isSubmit: false,
														disabled:
															!hasStepPermission,
														onclick: () => {
															handleNavigation(
																step.key
															);
														},
														fontSize: '1.5rem',
														minWidth: '7rem',
														minHeight: '2rem',
													},
												]}
											/>
										)}
									</List.Item>
								);
							}}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default PayrollSetupUI;
