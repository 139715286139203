import { HistoryOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { formatNumber, invalidText } from 'utils/utils';
const { Column } = Table;

type Props = {
	napsaLatestData: any;
	isLoading: boolean;
	showModal: () => void;
	totalRecords: number;
};

const ShareComponent = (data: any) => {
	const { record } = data;

	return (
		<Tooltip
			title={
				<>
					<p>EmployeeShare: {record.employeeShare} %</p>
					<p>EmployerShare: {record.employerShare} %</p>
				</>
			}
			placement="right"
			overlayInnerStyle={{ width: 'fit-content', height: 'fit-content' }}
		>
			{record.employerShare + record.employeeShare} %
		</Tooltip>
	);
};

const NapsaLatestTable = (props: Props) => {
	const { napsaLatestData, isLoading, showModal, totalRecords } = props;
const { data } = useSelector((state: any) => state?.userProfile);
	return (
		<div>
			<Table
				dataSource={napsaLatestData}
				rowKey={(record) => record.id}
				loading={isLoading}
				pagination={false}
			>
				<Column
					title="Effective Date"
					dataIndex="effectiveDate"
					key="effectiveDate"
					className="bg-white"
					width={'16%'}
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>
				<Column
					title="Percentage Share"
					key="percentageShare"
					className="bg-white"
					width={'20%'}
					render={(text, record: any) => (
						<ShareComponent record={record} />
					)}
				/>
				<Column
					title="Updated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					width={'15%'}
					render={(text) => text?.fullName}
				/>
				<Column
					title="Deduction Limit"
					dataIndex="napsaDeductionLimit"
					key="napsaDeductionLimit"
					className="bg-white"
					width={'18%'}
								render={(value: string) => {
						return formatNumber(Number(value), data?.baseCurrency);
					}}
				/>
				<Column
					title="Notes"
					dataIndex="notes"
					key="notes"
					className="bg-white"
					width={'15%'}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								tooltipMessage={text}
								isTooltip={true}
							/>
						)
					}
				/>
				<Column
					title="Attachment"
					dataIndex="attachment"
					key="attachment"
					className="bg-white"
					width={'18%'}
					render={(text, record: any) => (
						<div>
							{record.documentName ? (
								<a
									href={record.documentLink}
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.documentName}
										maxLength={20}
										key={record.documentName}
										tooltipMessage={record.documentName}
										isTooltip={true}
									/>
								</a>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				{totalRecords >= 1 && (
					<Column
						title="History"
						dataIndex="history"
						key="history"
						className="bg-white"
						width={'10%'}
						render={() => (
							<Button
								type="link"
								icon={<HistoryOutlined />}
								onClick={showModal}
							/>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default NapsaLatestTable;
