import { payrollApi } from 'Api/payroll';
import { viewPayrollApi } from 'Api/payrollView';
import { Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, toastText } from 'utils/utils';
import './index.scss';

type Props = {
	selectedPayrollId: string;
	setDeductionJSON: any;
	onlyView: boolean;
};

const TotalDeductionTable: React.FC<Props> = (props: Props) => {
	const { selectedPayrollId, setDeductionJSON, onlyView } = props;
	// const [showEmployer, setShowEmployer] = useState(false);

	const [deductionData, setDeductionData] = useState([]);
	const [isDeductionLoading, setIsDeductionLoading] = useState(false);
	const [columns, setColumns] = useState<any>([]);

	const { data } = useSelector((state: any) => state?.userProfile);

	// const handleSwitchChange = (checked: boolean) => {
	// 	setShowEmployer(checked);
	// 	generateColumns(columns);
	// };

	const generateColumns = (columnsData: any) => {
		const processColumn = (column: any) => {
			const { title, children, key } = column;
			// if (
			// 	!showEmployer &&
			// 	(key === 'napsaEmployerShare' || key === 'nhimaEmployerShare')
			// ) {
			// 	return null; // Skip these columns if showEmployer is false
			// }
			if (!children || children.length === 0) {
				return {
					id: Math.random(),
					title: title,
					dataIndex: key ? key : title,
					key: key ? key : title,
					width: '150px',
					className: 'children-normal-font-weight',
				};
			}

			return {
				title,
				children: children.map(processColumn).filter(Boolean),
				className: 'children-normal-font-weight',
			};
		};

		const groupedColumns = columnsData.reduce((acc: any, column: any) => {
			const { parent } = column;

			// Ensure parent group exists
			if (!acc[parent]) {
				acc[parent] = [];
			}
			acc[parent].push(processColumn(column));

			return acc;
		}, {});

		for (const key in groupedColumns) {
			const children = groupedColumns[key];
			children.forEach((child: any, index: number) => {
				child.className = `children-normal-font-weight ${
					index !== children.length - 1 ? 'border-right-none' : ''
				}`;
			});
		}

		// Convert grouped columns into the final format
		const convertedColumns = Object.keys(groupedColumns).map((parent) => ({
			// title:
			// 	parent === 'Statutory Components' ? (
			// 		<>
			// 			Statutory components
			// 			<Switch
			// 				onChange={(checked) => handleSwitchChange(checked)}
			// 				style={{ marginLeft: 10 }}
			// 			/>
			// 			<span style={{ marginLeft: 10 }}>Show employers</span>
			// 		</>
			// 	) : (
			// 		parent
			// 	),
			title: parent,
			className: 'background-color-dark',
			children: groupedColumns[parent],
		}));

		// Adding additional columns as needed
		convertedColumns.unshift({
			title: '',
			className: 'background-color-dark',
			children: [
				{
					title: 'Employees',
					dataIndex: 'Employee',
					key: 'Employee',
					fixed: 'left',
					width: 250,
					className: 'children-normal-font-weight',
					render: (text: string, record: any) => (
						<span>{record.id === 'total' ? text : `${text}`}</span>
					),
				},
			],
		});

		convertedColumns.push({
			title: 'Total',
			className: 'background-color-dark',
			children: [
				{
					title: '',
					dataIndex: 'total',
					key: 'total',
					width: '150px',
				},
			],
		});

		return convertedColumns;
	};

	const fetchDeductions = async (payRollId: string) => {
		try {
			setIsDeductionLoading(true);
			const response = await payrollApi.getPayrollDeductions(payRollId);
			const generatedColumns = generateColumns(
				response.data.data?.columns
			);

			setDeductionJSON(response.data.data?.rows);
			const formattedRowData = response.data.data?.rows.map(
				(item: any) => {
					const formattedItem: any = {};

					for (const key in item) {
						if (key == 'Employee') {
							formattedItem[key] = item[key];
						} else {
							// formattedItem[key] = item[key];
							formattedItem[key] = formatNumber(
								Number(item[key]),
								data?.baseCurrency
							);
						}
					}

					return formattedItem;
				}
			);

			setColumns(generatedColumns);
			setDeductionData(formattedRowData);
		} catch (err: any) {
			console.log('err: ', err);
			let message = 'Something went wrong in fetching earnings.';

			if (err?.response?.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsDeductionLoading(false);
		}
	};

	const fetchViewDeductions = async (payRollId: string) => {
		try {
			setIsDeductionLoading(true);
			const response = await viewPayrollApi.getAllEmployeeDeductions(
				payRollId
			);

			const generatedColumns = generateColumns(
				response.data.data?.columns
			);
			setDeductionJSON(response.data.data?.rows);
			const formattedRowData = response.data.data?.rows.map(
				(item: any) => {
					const formattedItem: any = {};

					for (const key in item) {
						if (key == 'Employee') {
							formattedItem[key] = item[key];
						} else {
							// formattedItem[key] = item[key];
							formattedItem[key] = formatNumber(
								Number(item[key]),
								data?.baseCurrency
							);
						}
					}

					return formattedItem;
				}
			);

			setColumns(generatedColumns);
			setDeductionData(formattedRowData);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsDeductionLoading(false);
		}
	};
	useEffect(() => {
		if (onlyView) {
			fetchViewDeductions(selectedPayrollId);
		} else {
			fetchDeductions(selectedPayrollId);
		}
	}, [selectedPayrollId]);
	return (
		<>
			<div>
				<Table
					columns={columns}
					dataSource={deductionData}
					className="total-deduction-table"
					bordered
					scroll={{ y: 'calc(100vh - 550px)' }}
					pagination={false}
					rowKey={(record: any) => record.id}
					loading={isDeductionLoading}
				/>
			</div>
		</>
	);
};

export default TotalDeductionTable;
