import { deleteApi, getApi, getApiPDF, postApi } from 'apis';

const getCompanyWizard = async () => {
	return await getApi('/payroll/payrollSettingWizard');
};

const getCompletedPayrollModules = async () => {
	return await getApi('/payroll/complete-payrollModules');
};

const getRemainingPayGroup = async (query: { payPeriodId: string }) => {
	return await getApi('/payroll/remaining-payGroup', query);
};

const getRemainingEmployeeList = async (query: { payPeriodId: string }) => {
	return await getApi('/payroll/remaining-employeeList', query);
};

const payGroupSetup = async () => {
	return await getApi('/payroll/payGroup-setup');
};

const getPayrollsHistory = async (params: any) => {
	return await getApi('/payroll/payrollHistory', params);
};

const uploadTranferReceipt = async (data: any) => {
	return await postApi('/payroll/payrollHistoryTransferFile', data, true);
};

const getEmployeePaymentDetails = async (query: any) => {
	return await getApi('/payroll/employeePaymentDetails', query);
};

const getPayslips = async (id: string) => {
	return await getApi('/payroll/employee-pay-slips', {
		payrollId: id,
	});
};

const getEmployeesLeaves = async (params: any) => {
	return await getApi('/payroll/getApprovedLeaves', params);
};

const savePayGroup = async (data: any) => {
	return await postApi('/payroll/payGroup-setup', data);
};

const payrollOverview = async (query: any) => {
	return await getApi('/payroll/payrollOverview', query);
};

const getPayrollDetails = async (id: string) => {
	return await getApi(`/payroll/details/${id}`);
};

const getPayrollEarnings = async (id: string) => {
	return await getApi(`/payroll/earnings`, {
		payrollId: id,
	});
};

const getAllEmployeeWorkHours = async (id: string) => {
	return await getApi('/payroll/employeeWorkHours', {
		payrollId: id,
	});
};

const getPayrollDeductions = async (id: string) => {
	return await getApi(`/payroll/deductions`, {
		payrollId: id,
	});
};

const getAllEmployeesNetPay = async (id: string) => {
	return await getApi('/payroll/employeeNetPay', {
		payrollId: id,
	});
};

const getPaymentForPayrollDetails = async (id: string) => {
	return await getApi('/payroll/get-payment-details', {
		payrollId: id,
	});
};

const deletePayroll = async (id: any) => {
	return await deleteApi(`/payroll/delete/${id}`);
};

const saveDeductions = async (data: any) => {
	return await postApi('/payroll/earnings-deductions', data);
};

const saveEarnings = async (data: any) => {
	return await postApi('/payroll/earnings-deductions', data);
};

const saveWorkingsHours = async (data: any) => {
	return await postApi('/payroll/save-working-hours', data);
};

const sendApproval = async (data: any) => {
	return await postApi('/payroll/send-approval', data);
};

const approvePayroll = async (data: any) => {
	return await postApi(`/payroll/approve`, data);
};

const rejectPayroll = async (data: any) => {
	return await postApi(`/payroll/reject`, data);
};

const proceedToPayment = async (data: any) => {
	return await postApi('/payroll/proceed-to-payment', data);
};

const exportPdfs = async (id: string) => {
	return await getApiPDF('/payroll/export/pdf', {
		payrollId: id,
	});
};

export const payrollApi = {
	getCompanyWizard,
	getRemainingPayGroup,
	getRemainingEmployeeList,
	payGroupSetup,
	getCompletedPayrollModules,
	getPayrollsHistory,
	getEmployeePaymentDetails,
	getPayslips,
	savePayGroup,
	payrollOverview,
	deletePayroll,
	getEmployeesLeaves,
	getPayrollDetails,
	getAllEmployeeWorkHours,
	getPayrollEarnings,
	getPayrollDeductions,
	getAllEmployeesNetPay,
	saveDeductions,
	saveEarnings,
	saveWorkingsHours,
	proceedToPayment,
	sendApproval,
	approvePayroll,
	rejectPayroll,
	exportPdfs,
	uploadTranferReceipt,
	getPaymentForPayrollDetails,
};
