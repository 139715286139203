import { postApi, getApi } from 'apis';

const generateNapsaReport = async (data: any) => {
	return await postApi('/reports/napsa', data);
};

const getNapsaReports = async (params: any) => {
	return await getApi('/reports/napsa', params);
};

const generateNhimaReport = async (data: any) => {
	return await postApi('/reports/nhima', data);
};

const getNhimaReports = async (params: any) => {
	return await getApi('/reports/nhima', params);
};

const generatePayeReport = async (data: any) => {
	return await postApi('/reports/paye/create', data, true);
};

const getPayeReports = async (params: any) => {
	return await getApi('/reports/paye', params);
};

const generatePayrollSummaryReport = async (data: any) => {
	return await postApi('/reports/payroll-summary/create', data, true);
};

const getPayrollSummaryReports = async (params: any) => {
	return await getApi('/reports/payroll-summary', params);
};

const generateP18Report = async (data: any) => {
	return await postApi('/reports/p18/create', data, true);
};

const getP18Reports = async (params: any) => {
	return await getApi('/reports/p18', params);
};

const getPayPeriodReports = async (moduleName: string, year: string) => {
	return await getApi('/reports/pay-period-reports', {
		moduleName,
		year,
	});
};

export const reportApis = {
	generateNapsaReport,
	getNapsaReports,
	generateNhimaReport,
	getNhimaReports,
	generatePayeReport,
	getPayeReports,
	generatePayrollSummaryReport,
	getPayrollSummaryReports,
	generateP18Report,
	getP18Reports,
	getPayPeriodReports,
};
