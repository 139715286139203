import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { companySetup } from 'Api/companySetup';
import { useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';

const ImageUpload = () => {
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState<string | undefined>(
		undefined
	);
	const [previewTitle, setPreviewTitle] = useState<string | undefined>(
		undefined
	);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj as Blob);
				reader.onload = () => resolve(reader.result as string);
				reader.onerror = (error) => reject(error);
			});
		}
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
		);
	};

	const handleChange = ({
		fileList: newFileList,
	}: {
		fileList: UploadFile[];
	}) => {
		setFileList(newFileList);
	};

	const handleCancel = () => setPreviewVisible(false);

	const beforeUpload = (file: File) => {
		const isImage = file.type.startsWith('image/');
		const isLt1M = file.size / 1024 / 1024 < 1; // 1MB limit

		if (!isImage) {
			toastText('You can only upload image files!', 'error');
			return Upload.LIST_IGNORE;
		}

		if (!isLt1M) {
			toastText('Image must be smaller than 1MB!', 'error');
			return Upload.LIST_IGNORE;
		}

		return true;
	};

	const uploadToBackend = async () => {
		if (fileList.length === 0) {
			toastText('Please upload at least one image', 'error');
			return;
		}

		const formData = new FormData();
		formData.append('moduleName', 'companyLogo');
		fileList.forEach((file) => {
			formData.append('file', file.originFileObj as Blob);
		});

		try {
			const data = await companySetup.uploadImage(formData);
			toastText('File uploaded successfully', 'success');
		} catch (error) {}
	};

	return (
		<div className={styles['upload-container']}>
			<div className={styles['horizontal-align']}>
				<Upload
					listType="picture-circle"
					fileList={fileList}
					onPreview={handlePreview}
					onChange={handleChange}
					beforeUpload={beforeUpload}
				>
					{fileList.length >= 1 ? null : (
						<div>
							<PlusOutlined />
							<div style={{ marginTop: 8 }}>Upload</div>
						</div>
					)}
				</Upload>

				<Button
					type="primary"
					onClick={uploadToBackend}
					disabled={fileList.length === 0}
				>
					Add Company Logo
				</Button>
			</div>

			<Modal
				open={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	);
};

export default ImageUpload;
