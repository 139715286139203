import { CloseOutlined } from '@ant-design/icons';
import { payrollApi } from 'Api/payroll';
import { Button, Divider, Modal, Steps } from 'antd';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseSvg, ToggleCloseButton, ToggleOpenButton } from 'utils/svgs';
import { invalidText, isSameArray, toastText } from 'utils/utils';
import EmployeeLeaveComponent from './EmployeeLeaves';
import NetPayComponent from './NetPay';
import PayGroupSetupComponent from './PayGroup';
import PaySlipComponent from './PaySlips';
import TotalDeductionComponent from './TotalDeduction';
import TotalEarningComponent from './TotalEarning';
import WorkHourComponent from './WorkHours';
import styles from './index.module.scss';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';

const { Step } = Steps;

const RunPayrollComponent = () => {
	const context = useContext(PermissionContext);
	// const add = context.allowedPermissions.includes('Add_Run_Payroll');
	const edit = context.allowedPermissions.includes('Edit_Run_Payroll');
	const view = context.allowedPermissions.includes('View_Run_Payroll');
	const navigate = useNavigate();
	const [current, setCurrent] = useState(0);

	const [searchParams] = useSearchParams();

	const [showSteps, setShowSteps] = useState(true);
	const [headerData, setHeaderData] = useState<any>(null);

	const [selectedPayrollId, setSelectedPayrollId] = useState('');

	// Loading
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isSaveNextLoading, setIsSaveNextLoading] = useState(false);
	const [isPayrollDetailsLoading, setIsPayrollDetailsLoading] =
		useState(false);

	// Pay Period

	const [selectedYear, setSelectedYear] = useState<any>(dayjs());
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');

	const [isEdit, setIsEdit] = useState(false);
	const [onlyView, setOnlyView] = useState(false);

	// Total Deduction

	const [deductionJSON, setDeductionJSON] = useState<any>(null);

	const [earningJSON, setEarningJSON] = useState<any>(null);

	//work hour data

	const [workHoursJSON, setWorkHoursJSON] = useState<any>(null);

	const [selectedPayGroup, setSelectedPayGroup] = useState<any[]>([]);
	const [payGroupOptions, setPayGroupOptions] = useState([]);
	const [payGroupData, setPayGroupData] = useState<any[]>([]);
	const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]);
	const [payGroupError, setPayGroupError] = useState({
		year: false,
		payPeriod: false,
		payGroup: false,
	});

	//confirmation modal
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [remainingEmployees, setRemainingEmployees] = useState<any[]>([]);

	const openConfirmationModal = () => {
		setShowConfirmationModal(true);
	};

	const closeConfirmationModal = () => {
		setShowConfirmationModal(false);
		setSelectedPayGroup(payGroupData);
	};

	const handleOkForModal = async () => {
		try {
			setConfirmLoading(true);
			await savePayroll();
		} catch (error) {
		} finally {
			setConfirmLoading(false);
		}

		setShowConfirmationModal(false);
	};

	const confirmModalButtons = [
		{
			text: 'Yes',
			isLoading: confirmLoading,
			className: 'btn-blue',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: handleOkForModal,
		},
		{
			text: 'No',
			className: 'secondary-button',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			isLoading: false,
			disabled: confirmLoading,
			onclick: closeConfirmationModal,
		},
	];

	// Fetch remaining pay-groups
	const fetchRemainingPayGroups = async (selectedPayPeriod: string) => {
		try {
			const response = await payrollApi.getRemainingPayGroup({
				payPeriodId: selectedPayPeriod,
			});

			const data = response.data.data.map((item: any) => {
				return {
					...item,
					label: item.name,
					value: item.id,
				};
			});

			setPayGroupOptions(data);
		} catch (err) {
			toastText('Something went wrong in fetching pay-groups.', 'error');
		}
	};

	// Fetch remaining pay-groups
	const fetchRemainingEmployees = async (selectedPayPeriod: string) => {
		try {
			const response = await payrollApi.getRemainingEmployeeList({
				payPeriodId: selectedPayPeriod,
			});

			const options = response.data?.data
				? response.data?.data?.map((item: any) => {
						return {
							value: item.id,
							label: `${item.firstName} ${item.lastName}`,
							employeeId: item.employeeId,
							isAdded: false,
						};
				  })
				: [];

			setRemainingEmployees(options);
		} catch (err) {
			toastText(
				'Something went wrong in fetching remaining employees.',
				'error'
			);
		}
	};

	// Select employee
	const addEmployee = async (employeeId: string) => {
		const findEmployee = remainingEmployees.find(
			(emp: any) => emp.value === employeeId
		);
		setSelectedEmployees((prev: any) => [...prev, findEmployee]);

		const employees = remainingEmployees.map((emp: any) => {
			if (emp.value === employeeId) {
				return {
					...emp,
					isAdded: true,
				};
			} else {
				return {
					...emp,
				};
			}
		});
		setRemainingEmployees(employees);
	};

	// Remove employee
	const removeEmployee = async (employeeId: string) => {
		setSelectedEmployees((prev: any) => {
			return prev.filter((item: any) => item !== employeeId);
		});

		const employees = remainingEmployees.map((emp: any) => {
			if (emp.value === employeeId) {
				return {
					...emp,
					isAdded: false,
				};
			} else {
				return {
					...emp,
				};
			}
		});
		setRemainingEmployees(employees);
	};

	// Handle Pay Period
	const handlePayPeriod = (value: string) => {
		setSelectedPayPeriod(value);
		if (invalidText(value)) {
			setSelectedEmployees([]);
			setRemainingEmployees([]);
			setPayGroupError((prev) => {
				return {
					...prev,
					payPeriod: true,
				};
			});
		} else {
			setPayGroupError((prev) => {
				return {
					...prev,
					payPeriod: false,
				};
			});
		}
	};

	// Fetch payroll data

	const fetchPayrollDetails = async (payRollId: string) => {
		try {
			setIsPayrollDetailsLoading(true);
			const response = await payrollApi.getPayrollDetails(payRollId);
			const data = response?.data?.data;

			if (data) {
				const parsedDate: any = dayjs(`${data.payYear}-01-01`);

				const payGroupIds = data.PayrollPayGroups.map(
					(item: any) => item.paygroupId
				);
				setPayGroupData(payGroupIds);

				setSelectedYear(parsedDate);
				setSelectedPayPeriod(data.payPeriodId);
				setSelectedPayGroup(payGroupIds);

				const payrollPayGroup = data?.PayrollPayGroups;
				let payGroupNames = '';
				let payGroupBranch = '';
				let payGroupDepartment = '';

				payrollPayGroup?.forEach((item: any) => {
					const { paygroup } = item;
					payGroupNames += paygroup?.name + ' ';
					const { PayGroupBranches, PayGroupDepartments } = paygroup;
					PayGroupBranches?.forEach((item: any) => {
						payGroupBranch += item?.branch.name + ' ';
					});
					PayGroupDepartments?.forEach((item: any) => {
						payGroupDepartment += item?.department.name + ' ';
					});
				});
				const _data = {
					payPeriodName: data?.payPeriod?.name,
					currency: data?.companyCurrency?.currency,
					payGroupNames,
					payGroupBranch,
					payGroupDepartment,
				};
				setHeaderData(_data);
			}
		} catch (err: any) {
			let message = 'Something went wrong in fetching payroll details.';

			if (err?.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsPayrollDetailsLoading(false);
		}
	};

	// Handle Pay Group
	const handlePayGroup = (value: any) => {
		setSelectedPayGroup(value);
		if (invalidText(value)) {
			setSelectedEmployees([]);
			setRemainingEmployees([]);
			setPayGroupError((prev) => {
				return {
					...prev,
					payGroup: true,
				};
			});
		} else {
			setPayGroupError((prev) => {
				return {
					...prev,
					payGroup: false,
				};
			});
		}
	};

	// Save pay group setup information

	const handleSubmit = async () => {
		try {
			setIsSaveNextLoading(true);
			await payrollApi.sendApproval({
				payrollId: selectedPayrollId,
			});
			toastText('Payroll has been sent for approval', 'success');
			navigate('/payroll-overview');
		} catch (error) {
			toastText('Something went wrong in sending approval.', 'error');
		} finally {
			setIsSaveNextLoading(false);
		}
	};

	const savePayroll = async (isDraft?: boolean) => {
		// If step 0 then save pay group
		if (current === 0) {
			if (invalidText(selectedPayPeriod)) {
				setPayGroupError((prev) => {
					return {
						...prev,
						payPeriod: true,
					};
				});
			}
			if (selectedPayGroup.length === 0) {
				setPayGroupError((prev) => {
					return {
						...prev,
						payGroup: true,
					};
				});
			}

			if (
				invalidText(selectedPayPeriod) ||
				selectedPayGroup.length === 0
			) {
				return;
			} else {
				await savePayGroup(isDraft);
			}
		}
	};

	const savePayGroup = async (isDraft?: boolean) => {
		try {
			if (isDraft) setIsSaveLoading(true);
			else setIsSaveNextLoading(true);

			const payGroup = await payrollApi.savePayGroup({
				payPeriodId: selectedPayPeriod,
				payGroupIds: selectedPayGroup,
				payYear: dayjs(selectedYear).year(),
				employeeIds: selectedEmployees.map((item) => item.value),
				payrollId: selectedPayrollId ? selectedPayrollId : '',
			});

			setSelectedPayrollId(payGroup?.data?.data?.id);
			const data = payGroup?.data?.data;
			if (data) {
				const payrollPayGroup = data?.PayrollPayGroups;

				let payGroupNames = '';
				let payGroupBranch = '';
				let payGroupDepartment = '';

				payrollPayGroup?.forEach((item: any) => {
					const { paygroup } = item;
					payGroupNames += paygroup?.name + ' ';
					const { PayGroupBranches, PayGroupDepartments } = paygroup;
					PayGroupBranches?.forEach((item: any) => {
						payGroupBranch += item?.branch.name + ' ';
					});
					PayGroupDepartments?.forEach((item: any) => {
						payGroupDepartment += item?.department.name + ' ';
					});
				});
				const _data = {
					payPeriodName: data?.payPeriod?.name,
					currency: data?.companyCurrency?.currency,
					payGroupNames,
					payGroupBranch,
					payGroupDepartment,
				};
				setHeaderData(_data);
			}
			if (isDraft) {
				navigate('/payroll-overview');
			} else {
				setCurrent((prevCurrent) => prevCurrent + 1);
			}
			toastText('Pay-Group setup completed successfully.', 'success');
		} catch (err: any) {
			let message = 'Something went wrong in saving pay group.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			if (isDraft) setIsSaveLoading(false);
			else setIsSaveNextLoading(false);
		}
	};

	const saveWorkingHours = async (isDraft?: boolean) => {
		try {
			if (isDraft) setIsSaveLoading(true);
			else setIsSaveNextLoading(true);

			await payrollApi.saveWorkingsHours({
				payrollId: selectedPayrollId,
				workHours: workHoursJSON,
			});
			toastText('Work hours saved successfully.', 'success');
			if (isDraft) {
				navigate('/payroll-overview');
			} else {
				setCurrent((prevCurrent) => prevCurrent + 1);
			}
		} catch (err: any) {
			let message = 'Something went wrong in saving work hours.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			if (isDraft) setIsSaveLoading(false);
			else setIsSaveNextLoading(false);
		}
	};

	const saveDeductions = async (isDraft?: boolean) => {
		try {
			if (isDraft) setIsSaveLoading(true);
			else setIsSaveNextLoading(true);

			await payrollApi.saveDeductions({
				payrollId: selectedPayrollId,
				deductions: deductionJSON,
			});
			toastText('Deductions saved successfully.', 'success');
			if (isDraft) {
				navigate('/payroll-overview');
			} else {
				setCurrent((prevCurrent) => prevCurrent + 1);
			}
		} catch (err: any) {
			let message = 'Something went wrong in saving deductions.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			if (isDraft) setIsSaveLoading(false);
			else setIsSaveNextLoading(false);
		}
	};

	const saveEarnings = async (isDraft?: boolean) => {
		try {
			if (isDraft) setIsSaveLoading(true);
			else setIsSaveNextLoading(true);

			await payrollApi.saveEarnings({
				payrollId: selectedPayrollId,
				earnings: earningJSON,
			});
			toastText('Earnings saved successfully.', 'success');

			if (isDraft) {
				navigate('/payroll-overview');
			} else {
				setCurrent((prevCurrent) => prevCurrent + 1);
			}
		} catch (err: any) {
			let message = 'Something went wrong in saving earnings.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			if (isDraft) setIsSaveLoading(false);
			else setIsSaveNextLoading(false);
		}
	};

	useEffect(() => {
		if (selectedPayPeriod) {
			fetchRemainingPayGroups(selectedPayPeriod);
			fetchRemainingEmployees(selectedPayPeriod);
		}
	}, [selectedPayPeriod]);

	useEffect(() => {
		if (selectedPayGroup && payGroupOptions.length > 0) {
			let employees: any = [];

			for (const i of selectedPayGroup) {
				const payGroup: any = payGroupOptions.find(
					(payGroup: any) => payGroup.id === i
				);
				if (payGroup) {
					employees = [...employees, ...payGroup.PayGroupEmployee];
				}
			}
		}
	}, [selectedPayGroup, payGroupOptions]);

	useEffect(() => {
		const payRollId = searchParams.get('payrollId');
		const onlyView = searchParams.get('onlyView');
		if (payRollId) {
			if (onlyView !== 'true' && !edit) {
				navigate(`/run-payroll?payrollId=${payRollId}&onlyView=true`);
			}
			setIsEdit(true);
			setSelectedPayrollId(payRollId);
		}
		if (onlyView === 'true') {
			setOnlyView(true);
		} else {
			setOnlyView(false);
		}

		if (onlyView === 'true') {
			if (!payRollId && !view) {
				navigate('/payroll-overview');
			}
		}
	}, [searchParams, view]);

	const steps = [
		{
			title: '1. Select pay group',
			content: (
				<PayGroupSetupComponent
					selectedPayGroup={selectedPayGroup}
					handlePayGroup={(value) => handlePayGroup(value)}
					selectedYear={selectedYear}
					handleSelectedYear={(value) => setSelectedYear(value)}
					selectedPayPeriod={selectedPayPeriod}
					handlePayPeriod={(value) => handlePayPeriod(value)}
					payGroupOptions={payGroupOptions}
					remainingEmployees={remainingEmployees}
					addEmployee={addEmployee}
					removeEmployee={removeEmployee}
					payGroupError={payGroupError}
					isPayrollDetailsLoading={isPayrollDetailsLoading}
					edit={isEdit}
					onlyView={onlyView}
					headerData={headerData}
				/>
			),
		},
		{
			title: '2. Working hours',
			content: (
				<WorkHourComponent
					selectedPayrollId={selectedPayrollId}
					setWorkHoursJSON={setWorkHoursJSON}
					onlyView={onlyView}
					headerData={headerData}
				/>
			),
		},
		{
			title: "3. Employee's leave",
			content: (
				<EmployeeLeaveComponent
					payrollId={selectedPayrollId}
					onlyView={onlyView}
					headerData={headerData}
				/>
			),
		},
		{
			title: '4. Total deductions',
			content: (
				<TotalDeductionComponent
					selectedPayrollId={selectedPayrollId}
					setDeductionJSON={setDeductionJSON}
					onlyView={onlyView}
					headerData={headerData}
				/>
			),
		},
		{
			title: '5. Total earnings',
			content: (
				<TotalEarningComponent
					selectedPayrollId={selectedPayrollId}
					setEarningJSON={setEarningJSON}
					onlyView={onlyView}
					headerData={headerData}
				/>
			),
		},
		{
			title: '6. Net pay',
			content: (
				<NetPayComponent
					selectedPayrollId={selectedPayrollId}
					headerData={headerData}
				/>
			),
		},
		{
			title: '7. Pay Slips',
			content: (
				<PaySlipComponent
					selectedPayrollId={selectedPayrollId}
					onlyView={onlyView}
					headerData={headerData}
				/>
			),
		},
	];

	const btn1 = {
		text: 'Back',
		isLoading: false,
		className: 'secondary-button',
		minWidth: '12rem',
		disabled: current === 0 || isSaveLoading || isSaveNextLoading,
		minHeight: '4rem',
		isSubmit: false,
		onclick: () => prev(),
	};
	const btn3 = {
		text: current === steps.length - 1 ? 'Save' : 'Next',
		className: 'btn-blue',
		minWidth: '12rem',
		isLoading: isSaveNextLoading,
		minHeight: '4rem',
		disabled: isSaveLoading || isSaveNextLoading,
		isSubmit: false,
		onclick: () => {
			if (current === steps.length - 1) {
				navigate('/payroll-overview');
			} else {
				setCurrent((prevCurrent) => prevCurrent + 1);
			}
		},
	};
	const viewButtons = [btn1, btn3];

	const buttons = [
		{
			text: 'Back',
			isLoading: false,
			className: 'secondary-button',
			minWidth: '12rem',
			disabled: current === 0 || isSaveLoading || isSaveNextLoading,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => prev(),
		},
		{
			text: 'Save as Draft',
			className: 'btn-blue',
			minWidth: '12rem',
			disabled: isSaveLoading || isSaveNextLoading,
			isLoading: isSaveLoading,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				saveAndDraft();
			},
		},
		{
			text:
				current === steps.length - 1
					? 'Send for Approval'
					: 'Save & Next',
			className: 'btn-blue',
			minWidth: '12rem',
			isLoading: isSaveNextLoading,
			minHeight: '4rem',
			disabled: isSaveLoading || isSaveNextLoading,
			isSubmit: false,
			onclick: () => {
				if (current === steps.length - 1) {
					handleSubmit();
				} else {
					next();
					// setCurrent((prevCurrent) => prevCurrent + 1);
				}
			},
		},
	];

	const handleStepChange = (newStep: number) => {
		if (newStep <= current) {
			setCurrent(newStep);
		}
	};

	const confirmationLogic = async () => {
		const isPayGroupSame = isSameArray(payGroupData, selectedPayGroup);
		if (!isPayGroupSame) {
			openConfirmationModal();
		} else await savePayroll();
	};

	const saveAndDraft = async () => {
		switch (current) {
			case 0:
				if (isEdit || selectedPayrollId) await confirmationLogic();
				else await savePayroll(true);
				break;
			case 1:
				await saveWorkingHours(true);
				break;
			case 2:
				setIsSaveLoading(true);
				setTimeout(() => {
					setIsSaveLoading(false);
					toastText('Employee leave saved successfully.', 'success');
					navigate('/payroll-overview');
				}, 2000);
				break;
			case 3:
				await saveDeductions(true);
				break;
			case 4:
				await saveEarnings(true);
				break;
			case 5:
				setIsSaveLoading(true);
				setTimeout(() => {
					setIsSaveLoading(false);
					toastText('Net pay saved successfully.', 'success');
					navigate('/payroll-overview');
				}, 2000);
				break;
			case 6:
				break;
			default:
				break;
		}
	};
	// const next = () => setCurrent(current + 1);
	const next = async () => {
		switch (current) {
			case 0:
				if (isEdit || selectedPayrollId) await confirmationLogic();
				else await savePayroll();
				break;
			case 1:
				await saveWorkingHours();
				break;
			case 2:
				setIsSaveNextLoading(true);
				setTimeout(() => {
					setIsSaveNextLoading(false);
					toastText('Employee leave saved successfully.', 'success');
					setCurrent((prevCurrent) => prevCurrent + 1);
				}, 2000);

				break;
			case 3:
				await saveDeductions();
				break;
			case 4:
				await saveEarnings();
				break;
			case 5:
				setIsSaveNextLoading(true);
				setTimeout(() => {
					setIsSaveNextLoading(false);
					toastText('Net pay saved successfully.', 'success');
					setCurrent((prevCurrent) => prevCurrent + 1);
				}, 2000);
				break;
			case 6:
				break;
			default:
				break;
		}
	};

	const prev = () => setCurrent(current - 1);

	useEffect(() => {
		if (selectedPayrollId) {
			setSelectedPayrollId(selectedPayrollId);
			fetchPayrollDetails(selectedPayrollId);
		}
	}, [selectedPayrollId]);
	return (
		<>
			<div className="modal-animation">
				<div className={styles['run-payroll']}>
					<div className={styles['run-payroll__header']}>
						<div className={styles['run-payroll__header__title']}>
							Run Payroll
						</div>
						<div
							className={styles['run-payroll__header__actions']}
							onClick={() => {
								navigate('/payroll-overview');
							}}
						>
							<CloseSvg />
						</div>
					</div>
					<div className={styles['run-payroll__table']}>
						{showSteps && (
							<div className="step-container">
								<div className="steps-header">
									<Steps
										current={current}
										// onChange={setCurrent}
										className="custom-steps"
										size="small"
										labelPlacement="vertical"
										onChange={handleStepChange}
									>
										{steps.map((item, index) => (
											<Step
												key={index}
												title={
													<>
														{item.title}
														{current > index && (
															<div className="completed-text">
																Completed
															</div>
														)}
													</>
												}
											/>
										))}
									</Steps>
								</div>
							</div>
						)}
						<Divider className="steps-divider" />
						<Button
							className="show-steps"
							onClick={() => setShowSteps(!showSteps)}
							icon={
								showSteps ? (
									<ToggleOpenButton />
								) : (
									<ToggleCloseButton />
								)
							}
						></Button>
						<div className="steps-content">
							{steps[current].content}
						</div>
						<div className="buttons-container">
							{onlyView ? (
								<Buttons
									buttons={viewButtons.filter(
										(button) =>
											!(
												button.text === 'Back' &&
												current === 0
											)
									)}
								/>
							) : (
								<Buttons
									buttons={buttons.filter(
										(button) =>
											!(
												button.text === 'Back' &&
												current === 0
											)
									)}
								/>
							)}
						</div>
					</div>
				</div>
			</div>

			{showConfirmationModal && (
				<>
					<Modal
						open={showConfirmationModal}
						onCancel={closeConfirmationModal}
						footer={null}
						closable={false}
						className="confirmation-modal"
					>
						<div className={`${styles['modal']} modal`}>
							<div className={styles['modal-header']}>
								<Title level={4}>
									Confirmation for Pay group change
								</Title>
								<div
									className={styles['close-icon']}
									onClick={closeConfirmationModal}
								>
									<CloseOutlined />
								</div>
							</div>
							<div className={styles['modal-body']}>
								<p>
									Changing the pay group will reset all data
									in the following step. This action is
									irreversible, and any unsaved changes will
									be lost.
								</p>
							</div>
							<div className={styles['modal-buttons']}>
								<Buttons buttons={confirmModalButtons} />
							</div>
						</div>
					</Modal>
				</>
			)}
		</>
	);
};

export default RunPayrollComponent;
