import { CloseOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import InputField from 'components/Global/InputField';
import TextareaFieldInput from 'components/Global/textareaField';
import { useEffect, useState } from 'react';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import ConfigRuleModal from '../ConfigRuleModal';
import CheckBox from 'components/Global/Checkbox';
import InputNumberField from 'components/Global/InputNumberField';
import { leaveManagementApi } from 'Api/masters/leave-management';

type AddLeaveManagementModalProps = {
	open: boolean;
	handleCancel: () => void;
	isEdit: boolean;
	editData: any;
	openConfigRuleModal: () => void;
	configRuleModal: boolean;
	fetchLeaveManagementData: () => void;
};

const AddLeaveManagementModal = (props: AddLeaveManagementModalProps) => {
	const {
		open,
		handleCancel,
		isEdit,
		editData,
		openConfigRuleModal,
		fetchLeaveManagementData,
		configRuleModal,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const [leaveManagementId, setLeaveManagementId] = useState<any>(null);
	const [configLoading, setConfigLoading] = useState(false);
	const [leaveHistoryId, setLeaveHistoryId] = useState<any>(null);
	const [leaveName, setLeaveName] = useState<string | null>(null);
	const [EditForConfig, setEditForConfig] = useState<boolean>(false);
	const [hasError, setHasError] = useState(false);
	const [addLeaveDetails, setAddLeaveDetails] = useState<any>({
		name: null,
		description: null,
		isAttachmentRequired: false,
		maxPaidLeavesPerMonth: null,
	});

	const [formError, setFormError] = useState<any>({
		name: false,
		description: false,
		maxPaidLeavesPerMonth: false,
	});

	//FOR CANCEL BUTTON ONLY
	const handleModalCancel = () => {
		setAddLeaveDetails({
			name: null,
			description: null,
			isAttachmentRequired: false,
			maxPaidLeavesPerMonth: null,
		});
		setFormError({
			name: false,
			description: false,
			maxPaidLeavesPerMonth: false,
		});
		setIsLoading(false);
		handleCancel();
	};

	const leaveButtons = [
		{
			text: isEdit ? 'Edit & next' : 'Save & next',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			disabled: isLoading,
			onclick: () => {
				handleSubmit();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				handleModalCancel();
			},
		},
	];

	const handleChange = (
		value: string | number | null | String[] | Boolean,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		if (name === 'maxPaidLeavesPerMonth') {
			console.log('value: ', value);
			if (value === null || value === 0 || Number(value) >= 30) {
				setFormError((prev: any) => {
					return {
						...prev,
						[name]: true,
					};
				});
			} else {
				setHasError(false);
			}
		}

		OnChange(value, name);
	};

	const OnChange = (
		value: string | number | null | String[] | Boolean,
		key: string
	) => {
		setAddLeaveDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...addLeaveDetails },
			{ ...formError }
		);

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		}

		const data = {
			name: addLeaveDetails.name,
			description: addLeaveDetails.description,
			attachmentRequired: addLeaveDetails.isAttachmentRequired,
			maximumLeavePerMonth: addLeaveDetails.maxPaidLeavesPerMonth,
		};

		if (isEdit) {
			try {
				setIsLoading(true);
				const response = await leaveManagementApi.editLeaveManagement(
					editData.id,
					data
				);
				toastText('Leave Type updated Successfully', 'success');
				setEditForConfig(true);
				if (response.data.data) {
					setLeaveManagementId(response?.data?.data?.id);
					setLeaveHistoryId(
						response?.data?.data?.leaveManagementHistoryId
					);
					setLeaveName(addLeaveDetails.name);
				}
			} catch (error) {
			} finally {
				setIsLoading(false);

				//do diffrent
				openConfigRuleModal();
			}
		} else {
			try {
				setIsLoading(true);

				const response = await leaveManagementApi.addLeaveManagement(
					data
				);
				toastText('Leave Type added Successfully', 'success');
				setEditForConfig(false);
				if (response.data.data) {
					setLeaveManagementId(response?.data?.data?.id);
					setLeaveName(addLeaveDetails.name);
					handleModalCancel();
					openConfigRuleModal();
				}
			} catch (error) {
				toastText('Error in adding Leave Type', 'error');
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleSubmitForConfiguration = async (_configurationRules: any) => {
		if (EditForConfig) {
			try {
				setConfigLoading(true);

				await leaveManagementApi.editLeaveConfiguration(
					leaveManagementId,
					{
						leaveHistoryId: leaveHistoryId,
						configurationRules: _configurationRules,
					}
				);
				toastText('Configuration updated successfully', 'success');
			} catch (error) {
				toastText(
					'Something went wrong in editing configuration',
					'error'
				);
			} finally {
				setConfigLoading(false);
			}
		} else {
			try {
				setConfigLoading(true);

				await leaveManagementApi.addConfigurationRoles(
					leaveManagementId,
					{
						configurationRules: _configurationRules,
					}
				);
				toastText('Configuration saved successfully', 'success');
			} catch (error) {
				toastText(
					'Something went wrong in saving configuration',
					'error'
				);
			} finally {
				setConfigLoading(false);
			}
		}
	};

	useEffect(() => {
		if (isEdit) {
			setAddLeaveDetails({
				name: editData.name,
				description: editData.description,
				isAttachmentRequired: editData.isAttachmentRequired,
				maxPaidLeavesPerMonth: editData.maxPaidLeavesPerMonth,
			});
			setEditForConfig(true);
		} else {
			setEditForConfig(false);
		}
	}, [isEdit]);

	return (
		<>
			<Modal
				open={open}
				onCancel={handleModalCancel}
				closable={false}
				width={600}
				footer={null}
				destroyOnClose={true}
				maskClosable={false}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<h4> {isEdit ? 'Edit leave' : 'Add leave'} </h4>
						<div
							className={styles['close-icon']}
							onClick={handleModalCancel}
						>
							<CloseOutlined />
						</div>
					</div>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<Row className={styles['form-container-card']} gutter={50}>
						<Col span={24} className={styles['col']}>
							<InputField
								name="name"
								value={addLeaveDetails.name}
								label="Name"
								required={true}
								helperText="Leave name required"
								onChange={(value) => {
									handleChange(value, 'name', true);
								}}
								isError={formError.name}
								disabled={false}
							/>
						</Col>
						<Col span={24} className={styles['col']}>
							<InputNumberField
								name="maxPaidLeavesPerMonth"
								value={addLeaveDetails.maxPaidLeavesPerMonth}
								label="Max Paid Leaves Per Month"
								required={true}
								helperText="Max Paid Leaves Per Month required"
								onChange={(value) => {
									handleChange(
										value,
										'maxPaidLeavesPerMonth',
										true
									);
								}}
								isError={formError.maxPaidLeavesPerMonth}
								disabled={false}
							/>
						</Col>
						<Col span={24}>
							<TextareaFieldInput
								name="description"
								value={addLeaveDetails.description}
								label="Description"
								required={true}
								helperText="Description required"
								onChange={(value) => {
									handleChange(value, 'description', true);
								}}
								isError={formError.description}
								disabled={false}
							/>
						</Col>
						<Col span={24} className={styles['col']}>
							<div className={styles['checkbox-wrapper']}>
								<CheckBox
									checked={
										addLeaveDetails.isAttachmentRequired
									}
									label="Attachment Required"
									onChange={(value) =>
										handleChange(
											value,
											'isAttachmentRequired',
											false
										)
									}
								/>
							</div>
						</Col>
					</Row>

					<div className={styles['buttons']}>
						<Buttons buttons={leaveButtons} />
					</div>
				</div>
			</Modal>
			{configRuleModal && (
				<ConfigRuleModal
					open={configRuleModal}
					leaveManagementId={leaveManagementId}
					fetchLeaveManagementData={fetchLeaveManagementData}
					leaveName={leaveName}
					closeModal={handleModalCancel}
					configLoading={configLoading}
					handleSubmitForConfiguration={handleSubmitForConfiguration}
				/>
			)}
		</>
	);
};

export default AddLeaveManagementModal;
