import GlobalHeader from '../GlobalHeader';
import TotalEarningTable from './Table';
import styles from './index.module.scss';

type TotalEarningProps = {
	selectedPayrollId: string;
	setEarningJSON: any;
	onlyView: boolean;
	headerData: any;
};
const TotalEarningComponent = (props: TotalEarningProps) => {
	const { selectedPayrollId, setEarningJSON, onlyView, headerData } = props;

	return (
		<div className={styles['total-earning__table']}>
			<GlobalHeader headerData={headerData} />
			<TotalEarningTable
				selectedPayrollId={selectedPayrollId}
				setEarningJSON={setEarningJSON}
				onlyView={onlyView}
			/>
		</div>
	);
};

export default TotalEarningComponent;
