import { Col, Row } from 'antd';
import { PaySlipsImage } from 'utils/svgs';
import GlobalHeader from '../GlobalHeader';
import styles from './index.module.scss';
import PaySlipTable from './Table';

type PaySlipProps = {
	selectedPayrollId: string;
	onlyView: boolean;
	headerData: any;
};
const PaySlipComponent = (props: PaySlipProps) => {
	const { selectedPayrollId, onlyView, headerData } = props;

	return (
		<>
			<Row>
				<Col span={24}>
					<GlobalHeader Payslip={false} headerData={headerData} />
				</Col>
			</Row>
			<Row>
				<Col span={14}>
					<div className={styles['pay-Slip__table']}>
						<PaySlipTable
							selectedPayrollId={selectedPayrollId}
							onlyView={onlyView}
						/>
					</div>
				</Col>
				<Col span={3}></Col>
				<Col span={7}>
					<div>
						<PaySlipsImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default PaySlipComponent;
