import { Card, Col, Row } from 'antd';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  changeTab: (key: string) => void;
  onCancel: () => void;
  companyData: any;
};

const ViewBankDetails = ({ companyData }: Props) => {
  const { BankDetails } = companyData;

  return (
    <div className={styles['scrollable-content']}>
      <Row gutter={16}>
        {BankDetails.map((bank: any, index: number) => (
          <Col span={24} key={bank.id}>
            <Card
              title={
                <div className={styles['ant-card-head-title']}>
                  {`Bank ${index + 1}: ${bank.accountName}`}
                </div>
              }
              bordered={false}
              className={styles['card']}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <p><b>Account Name:</b> {bank.accountName}</p>
                </Col>
                <Col span={12}>
                  <p><b>Account Number:</b> {bank.accountNumber}</p>
                </Col>
                <Col span={12}>
                  <p><b>Branch Name:</b> {bank.branchName}</p>
                </Col>
                <Col span={12}>
                  <p><b>Branch Code:</b> {bank.branchCode}</p>
                </Col>
                <Col span={12}>
                  <p><b>Sort Code:</b> {bank.sortCode}</p>
                </Col>
                <Col span={12}>
                  <p><b>SWIFT Code:</b> {bank.swiftCode}</p>
                </Col>
                <Col span={12}>
                  <p><b>Account Type:</b> {bank.accountType}</p>
                </Col>
                <Col span={12}>
                  <p><b>Bank Type:</b> {bank.bankType}</p>
                </Col>
                <Col span={12}>
                  <p><b>Verified:</b> {bank.isVerified ? 'Yes' : 'No'}</p>
                </Col>
                <Col span={12}>
                  <p><b>Fund Transfer Enabled:</b> {bank.isFundTransfer ? 'Yes' : 'No'}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ViewBankDetails;
