import { Table } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import { EmployeeTableInterface } from 'interfaces/employee.interface';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/utils';

const { Column, ColumnGroup } = Table;

type Props = {
	tableData: EmployeeTableInterface[];
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const TimeActivitiesTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;
	const [searchParams, setSearchParams] = useSearchParams();

	const permissionContext = useContext(PermissionContext);

	const handleEditEmployee = (id: string) => {
		setSearchParams({
			employeeId: id,
		});
	};

	const { data } = useSelector((state: any) => state?.userProfile);
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				// scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Employee Name and Code"
					dataIndex="optionalEmployeeId"
					key="optionalEmployeeId"
					sorter={true}
					render={(text, record: any) => (
						<>
							<span>
								{record.firstName} {record.lastName}
							</span>
							<br />
							<span className="color-purple">
								{record?.optionalEmployeeId}
							</span>
						</>
					)}
				/>
				<Column
					title={
						<>
							<div>Salary Rate</div>
							<div
								style={{
									color: 'lightgrey',
									fontSize: 'small',
								}}
							>
								In Kwacha
							</div>
						</>
					}
					dataIndex="monthlySalary"
					key="monthlySalary"
					sorter={true}
					render={(value: string) => {
						return value
							? formatNumber(Number(value), data?.baseCurrency)
							: 'N/A';
					}}
					// render={(value: string) => {
					// 	return ` ${value ? `${value}` : 'N/A'}`;
					// }}
				/>
				<Column
					title="Regular Hours"
					dataIndex="totalRegularHours"
					key="totalRegularHours"
					sorter={true}
					// render={(value: string) =>
					// 	dayjs(value).format('DD/MM/YYYY')
					// }
				/>
				<Column
					title="Weekday OT"
					dataIndex="weekdaysOT"
					key="weekdaysOT"
					sorter={true}
				/>
				<Column
					title="Sunday OT"
					dataIndex="sundayOT"
					key="sundayOT"
					sorter={true}
				/>
				<Column
					title="Shift Difference"
					dataIndex="totalTimeShiftHours"
					key="totalTimeShiftHours"
					sorter={true}
				/>
				<Column
					title="Total Hours"
					dataIndex="totalHours"
					key="totalHours"
					render={(value: string) => {
						return <span className="color-purple"> {value}</span>;
					}}
				/>
				{/* <Column title="Leaves" dataIndex="leaves" key="leaves" /> */}
			</Table>
		</div>
	);
};

export default TimeActivitiesTable;
