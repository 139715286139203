import { Table } from 'antd';
import dayjs from 'dayjs';
import { formatNumber } from 'utils/utils';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';

const { Column } = Table;

type Props = {
	paymentInfoData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	isLoading: boolean;
};

const PaymentInfoDetails = (props: Props) => {
	const {
		paymentInfoData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,
		tableChangeHandler,
	} = props;
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={paymentInfoData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				{/* <Column
					title="Payment Method"
					dataIndex="paymentMethod"
					key="paymentMethod"
					className="bg-white"
				/> */}
				<Column
					title="Account Holder Name"
					dataIndex="accountHolderName"
					key="accountHolderName"
					className="bg-white"
				/>
				<Column
					title="Bank Name"
					render={(text, record: any) =>
						record?.bankDetails?.accountName || '-'
					}
					key="bankName"
					className="bg-white"
				/>
				<Column
					title="Account Number"
					dataIndex="accountNumber"
					key="accountNumber"
					className="bg-white"
				/>
				<Column
					title="Sort Code"
					dataIndex="sortCode"
					key="sortCode"
					className="bg-white"
				/>
			</Table>
		</div>
	);
};

export default PaymentInfoDetails;
