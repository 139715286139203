import { Card, Col, Row } from 'antd';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  changeTab: (key: string) => void;
  onCancel: () => void;
  companyData: any;
};



const ViewCompanyDetails = ({ companyData }: Props) => {
  console.log('company companyDetails :: ',companyData.CompanyDetails)
  return (
    <div className={styles['scrollable-content']}>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            title={<div className={styles['ant-card-head-title']}>Company Information</div>}
            bordered={false}
            className={styles['card']}
          >
            <Row gutter={16}>
              <Col span={12}>
                <p><b>Company Name:</b> {companyData.name}</p>
              </Col>
              <Col span={12}>
                <p><b>Email:</b> {companyData.email}</p>
              </Col>
              <Col span={12}>
                <p><b>Tpin:</b> {companyData.tpin}</p>
              </Col>
               <Col span={12}>
                <p><b>Napsa:</b> {companyData?.CompanyDetails?.napsa}</p>
              </Col>
               <Col span={12}>
                <p><b>Nhima:</b> {companyData?.CompanyDetails?.nihma}</p>
              </Col>
               <Col span={12}>
                <p><b>Financial Year:</b> {companyData?.CompanyDetails?.financialYear}</p>
              </Col>
               <Col span={12}>
                <p><b>Date Format:</b> {companyData?.CompanyDetails?.dateFormat}</p>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Company Location
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
					  <Col span={12}>
                <p><b>Address 1:</b> {companyData?.CompanyDetails?.address1}</p>
              </Col>
               <Col span={12}>
                <p><b>Address 2:</b> {companyData?.CompanyDetails?.address2}</p>
              </Col>
               <Col span={12}>
                <p><b>City:</b> {companyData?.CompanyDetails?.city}</p>
              </Col>
               <Col span={12}>
                <p><b>State:</b> {companyData?.CompanyDetails?.state}</p>
              </Col>
               <Col span={12}>
                <p><b>Country:</b> {companyData?.CompanyDetails?.country}</p>
              </Col>
               <Col span={12}>
                <p><b>Country:</b> {companyData?.CompanyDetails?.postalCode}</p>
              </Col>
						</Row>
					</Card>
				</Col>

            <Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Company Contact Person Detail
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
					  <Col span={12}>
                <p><b>Name:</b> {companyData?.ContactDetails[0]?.name}</p>
              </Col>
               <Col span={12}>
                <p><b>Title:</b> {companyData?.ContactDetails[0]?.title}</p>
              </Col>
               <Col span={12}>
                <p><b>Phone</b> {companyData?.ContactDetails[0]?.phone}</p>
              </Col>
						</Row>
					</Card>
				</Col>
      </Row>
    </div>
  );
};

export default ViewCompanyDetails;
