import { Card, Col, Row } from 'antd';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  changeTab: (key: string) => void;
  onCancel: () => void;
  companyData: any;
};

const ViewCurrencyDetails = ({ companyData }: Props) => {
  const { CompanyCurrency } = companyData;

  return (
    <div className={styles['scrollable-content']}>
      <Row gutter={16}>
        {CompanyCurrency.map((currency: any) => (
          <Col span={24} key={currency.id}>
            <Card
              title={
                <div className={styles['ant-card-head-title']}>
                  {currency.isBaseCurrency ? 'Base Currency' : 'Currency'}: {currency.currency}
                </div>
              }
              bordered={false}
              className={styles['card']}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <p><b>Symbol:</b> {currency.symbol}</p>
                </Col>
                <Col span={12}>
                  <p><b>Decimal Separator:</b> {currency.decimalSeparator}</p>
                </Col>
                <Col span={12}>
                  <p><b>Currency Position:</b> {currency.currencyPosition}</p>
                </Col>
                <Col span={12}>
                  <p><b>Negative Display:</b> {currency.negativeDisplay}</p>
                </Col>
                <Col span={12}>
                  <p><b>Display Format:</b> {currency.displayFormat}</p>
                </Col>
                <Col span={12}>
                  <p><b>Thousand Separator:</b> {currency.thousandSeparator}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ViewCurrencyDetails;
